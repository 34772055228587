import React, { useEffect, useMemo, useState } from "react";

import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import { IconButton, MenuItem, Popover, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function LanguagePopover() {
  const { t, i18n } = useTranslation();
  const { openNav } = useSelector((state) => state.serviceReducer);
  const [open, setOpen] = useState(null);

  const LANGS = useMemo(
    () => [
      {
        value: "ru",
        shortLabel: t("main.lang.ru.shortLabel"),
        label: t("main.lang.ru.label"),
        // icon: '/assets/icons/ic_flag_ru.svg',
      },
      {
        value: "en",
        shortLabel: t("main.lang.en.shortLabel"),
        label: t("main.lang.en.label"),
        // icon: '/assets/icons/ic_flag_en.svg',
      },
    ],
    [t],
  );

  const [selectedLang, setSelectedLang] = useState("");
  // const [selectedLang, setSelectedLang] = useState(LANGS[LANGS.findIndex((locale) => locale.value === i18n.resolvedLanguage)]);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleChangeLanguage = (locale) => {
    i18n.changeLanguage(locale.value);
    // setSelectedLang(locale)
    setOpen(null);
  };

  useEffect(() => {
    setSelectedLang(
      LANGS[
        LANGS.findIndex((locale) => locale.value === i18n.resolvedLanguage)
      ],
    );
  }, [LANGS, i18n.resolvedLanguage]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          color: "layout.element",
        }}
      >
        {/*{selectedLang.shortLabel}*/}
        <TranslateOutlinedIcon />
      </IconButton>

      <Popover
        PaperProps={{
          sx: {
            backgroundColor: "background.main",
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
        anchorEl={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setOpen(null)}
        open={Boolean(open)}
        sx={{ zIndex: "10000" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((locale) => (
            <MenuItem
              key={locale.value}
              onClick={() => handleChangeLanguage(locale)}
              selected={locale.value === selectedLang.value}
            >
              {/*<Box component="img" alt={locale.label} src={locale.icon} sx={{ width: 28, mr: 2 }} />*/}

              {locale.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
