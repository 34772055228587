import React from "react";

import { useTranslation } from "react-i18next";

import CustomMultiTextFieldAutocompleteForObject from "../../Fields/CustomMultiTextFieldAutocompleteForObject";
import CustomTextField from "../../Fields/CustomTextField";

const AccessUserFields = ({ autocompleteOptions }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomTextField
        label={t(
          "src.components.Forms.FormFields.AccessGroupsFields.table_email",
          "E-mail",
        )}
        name="email"
        required
      />
      <CustomTextField
        label={t(
          "src.components.Forms.FormFields.AccessGroupsFields.table_name",
          "Имя",
        )}
        name="name"
        required
      />
      <CustomTextField
        label={t(
          "src.components.Forms.FormFields.AccessGroupsFields.table_lastName",
          "Фамилия",
        )}
        name="last_name"
        required
      />
      <CustomMultiTextFieldAutocompleteForObject
        label={t(
          "src.components.Forms.FormFields.AccessGroupsFields.table_groups",
          "Группа",
        )}
        multiple={true}
        name="groups"
        options={autocompleteOptions}
        rules={{
          required: t("main.another.requiredField"),
        }}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
      />
    </>
  );
};

export default AccessUserFields;
