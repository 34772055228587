import React from "react";

// @mui
import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { RegisterForm } from "../../components/Forms/RegisterForm";
import { StyledContent, StyledRoot } from "../../theme/standarts_styles";

const Register = () => {
  const location = useLocation();
  const currentLocation = location.pathname === "/register";
  const { t } = useTranslation();

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography align="center" gutterBottom variant="h4">
              {currentLocation
                ? t("src.pages.Register.registerPageText")
                : t("src.pages.Register.changePasswordPageText")}
            </Typography>
            <RegisterForm currentLocation={currentLocation} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};

export default Register;
