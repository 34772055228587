import React, { createContext, useState } from "react";

import LoadingScreen from "../components/modals/loadingScreen";
import LoadingSpinner from "../components/modals/loadingSpinner";

export const LoadContext = createContext(null);

export const LoadProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false); // TODO redux service

  let firstview = localStorage.getItem("firstview")
    ? localStorage.getItem("firstview") === "true"
    : true; // TODO redux service

  const value = { setIsLoading };

  return (
    <LoadContext.Provider value={value}>
      {children}
      {firstview && isLoading && <LoadingScreen />}
      {!firstview && isLoading && <LoadingSpinner />}
    </LoadContext.Provider>
  );
};
