import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptions: [],
  subscription: null,
  selectedSubscription: null,
  transactions: [],
};

const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setSubscriptions(state, { payload }) {
      state.subscriptions = payload;
    },
    setSubscription(state, { payload }) {
      state.subscription = payload;
    },
    setSelectedSubscription(state, { payload }) {
      const index = state.subscriptions.findIndex(
        (subscription) => subscription?.SubscriptionID === payload,
      );
      state.selectedSubscription = state.subscriptions[index];
    },
    setTransactions(state, { payload }) {
      state.transactions = payload;
    },
  },
});

export const { actions: subscriptionsActions, reducer: subscriptionsReducer } =
  SubscriptionsSlice;
export default SubscriptionsSlice.reducer;
