import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Card, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CustomViewField from "../../components/CustomViewField";
import CustomTextField from "../../components/Fields/CustomTextField";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

const MyData = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { setCompanyData } = useActions();

  const companyData = useSelector((state) => state.companyReducer);

  const methods = useForm({
    mode: "onBlur",
    defaultValues: companyData,
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/user/me`);
      setCompanyData(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setCompanyData]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.patch(`/user/me/update`, data);
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      setEditMode(false);
      loadData();
    }
  }, [isSubmitSuccessful, loadData]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleBack = () => {
    setEditMode(false);
  };

  const handleReset = () => {
    reset();
  };

  if (editMode) {
    return (
      <Container>
        <TitleStack>
          <Typography variant="h4">{t("src.pages.MyData.myData")}</Typography>
        </TitleStack>
        <Box pt={2}>
          <Card sx={{ mb: 2, p: 3 }}>
            {loading && <LoadingIndicator />}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="space-between" spacing={2}>
                  <Grid item md={4} xs={12}>
                    <Typography sx={{ fontWeight: "bold", pb: 3 }}>
                      {t("src.pages.MyData.companyInformation")}
                    </Typography>
                    <CustomTextField
                      label={t("src.pages.MyData.companyName")}
                      name="CompanyName"
                      required
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.companyIIN")}
                      name="CompanyIIN"
                      required
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.companyCountry")}
                      name="CompanyCountry"
                      required
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.companySite")}
                      name="CompanyWebsite"
                      required
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={{ fontWeight: "bold", pb: 3 }}>
                      {t("src.pages.MyData.contactPerson")}
                    </Typography>
                    <CustomTextField
                      label={t("src.pages.MyData.surname")}
                      name="UserLastName"
                      required
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.name")}
                      name="UserName"
                      required
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.patronymic")}
                      name="otchestvo"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.role")}
                      name="role"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.phone")}
                      name="phone"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.email")}
                      name="Email"
                      required
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Typography sx={{ fontWeight: "bold", pb: 3 }}>
                      {t("src.pages.MyData.address")}
                    </Typography>
                    <CustomTextField
                      label={t("src.pages.MyData.index")}
                      name="index"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.country")}
                      name="country"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.region")}
                      name="oblast"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.city")}
                      name="city"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.street")}
                      name="street"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.house")}
                      name="house"
                    />
                    <CustomTextField
                      label={t("src.pages.MyData.apartment")}
                      name="flat"
                    />
                  </Grid>
                </Grid>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" spacing={2}>
                    <Button type="submit" variant="contained">
                      {t("main.another.save")}
                    </Button>
                    <Button onClick={handleBack} variant="text">
                      {t("main.another.back")}
                    </Button>
                  </Stack>
                  <Button onClick={handleReset} variant="text">
                    {t("main.another.reset")}
                  </Button>
                </Stack>
              </form>
            </FormProvider>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">{t("src.pages.MyData.myData")}</Typography>
      </TitleStack>
      <Box pt={2}>
        <Card sx={{ mb: 2, p: 3 }}>
          {loading && <LoadingIndicator />}
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography sx={{ fontWeight: "bold", pb: 2 }}>
                {t("src.pages.MyData.companyInformation")}
              </Typography>
              <CustomViewField
                label={t("src.pages.MyData.companyName")}
                text={companyData.CompanyName}
              />
              <CustomViewField
                label={t("src.pages.MyData.companyIIN")}
                text={companyData.CompanyIIN}
              />
              <CustomViewField
                label={t("src.pages.MyData.companyCountry")}
                text={companyData.CompanyCountry}
              />
              <CustomViewField
                label={t("src.pages.MyData.companySite")}
                text={companyData.CompanyWebsite}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography sx={{ fontWeight: "bold", pb: 2 }}>
                {t("src.pages.MyData.contactPerson")}
              </Typography>
              <CustomViewField
                label={t("src.pages.MyData.surname")}
                text={companyData.UserLastName}
              />
              <CustomViewField
                label={t("src.pages.MyData.name")}
                text={companyData.UserName}
              />
              <CustomViewField
                label={t("src.pages.MyData.patronymic")}
                text={""}
              />
              <CustomViewField label={t("src.pages.MyData.role")} text={""} />
              <CustomViewField label={t("src.pages.MyData.phone")} text={""} />
              <CustomViewField
                label={t("src.pages.MyData.email")}
                text={companyData.Email}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography sx={{ fontWeight: "bold", pb: 2 }}>
                {t("src.pages.MyData.address")}
              </Typography>
              <CustomViewField label={t("src.pages.MyData.index")} text={""} />
              <CustomViewField
                label={t("src.pages.MyData.country")}
                text={""}
              />
              <CustomViewField label={t("src.pages.MyData.region")} text={""} />
              <CustomViewField label={t("src.pages.MyData.city")} text={""} />
              <CustomViewField label={t("src.pages.MyData.street")} text={""} />
              <CustomViewField label={t("src.pages.MyData.house")} text={""} />
              <CustomViewField
                label={t("src.pages.MyData.apartment")}
                text={""}
              />
            </Grid>
          </Grid>
          <Button onClick={handleEdit} variant="contained">
            {t("main.another.edit")}
          </Button>
        </Card>
      </Box>
    </Container>
  );
};

export default MyData;
