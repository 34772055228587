import { Box, Typography } from "@mui/material";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography pr={1} variant="body2">
        © {year}{" "}
        <a href="https://aspex.cloud" rel="noreferrer" target="_blank">
          Aspex
        </a>
        .
      </Typography>
      <Typography variant="body2">
        <a
          href="https://aspex.cloud/privacypolicy"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </Typography>
    </Box>
  );
};

export default Footer;
