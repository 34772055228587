import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channels: [],
  channelsTypeFormIsAdd: true,
  selectedChannel: null,
  questions: [],
  selectedQuestions: [],
};

const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setChannels(state, { payload }) {
      state.channels = payload;
    },
    addQuestions(state, { payload }) {
      state.selectedQuestions = [...state.selectedQuestions, payload];
    },
    deleteQuestions(state, { payload }) {
      const FindIndex = state.selectedQuestions.findIndex(
        (question) => question.index === payload,
      );
      state.selectedQuestions = state.selectedQuestions
        .slice(0, FindIndex)
        .concat(state.selectedQuestions.slice(FindIndex + 1));
    },

    setChannelsTypeFormIsAdd(state, { payload }) {
      state.channelsTypeFormIsAdd = payload;
    },
    updateQuestionInSelectedQuestions(state, { payload }) {
      const FindIndex = state.selectedQuestions.findIndex(
        (question) => question.index === payload.id,
      );
      switch (payload.field) {
        case "question": {
          state.selectedQuestions[FindIndex].question = payload.value;
          break;
        }
        case "type": {
          state.selectedQuestions[FindIndex].type = payload.value;
          break;
        }
        default:
          break;
      }
    },
    setSelectedQuestions(state, { payload }) {
      if (payload === null) {
        state.selectedQuestions = [];
      } else {
        state.selectedQuestions = [];
        const index = state.channels.findIndex(
          (channel) => channel.id === payload,
        );
        const current_data = state.channels[index];

        for (let i = 1; i <= current_data.custom_count; i++) {
          switch (i) {
            case 1: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_1,
                  type: current_data.custom_1_type,
                },
              ];
              break;
            }
            case 2: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_2,
                  type: current_data.custom_2_type,
                },
              ];
              break;
            }
            case 3: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_3,
                  type: current_data.custom_3_type,
                },
              ];
              break;
            }
            case 4: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_4,
                  type: current_data.custom_4_type,
                },
              ];
              break;
            }
            case 5: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_5,
                  type: current_data.custom_5_type,
                },
              ];
              break;
            }
            case 6: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_6,
                  type: current_data.custom_6_type,
                },
              ];
              break;
            }
            case 7: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_7,
                  type: current_data.custom_7_type,
                },
              ];
              break;
            }
            case 8: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_8,
                  type: current_data.custom_8_type,
                },
              ];
              break;
            }
            case 9: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_9,
                  type: current_data.custom_9_type,
                },
              ];
              break;
            }
            case 10: {
              state.selectedQuestions = [
                ...state.selectedQuestions,
                {
                  index: i,
                  question: current_data.custom_10,
                  type: current_data.custom_10_type,
                },
              ];
              break;
            }
            default:
              break;
          }
        }
      }
    },
    setSelectedChannel(state, { payload }) {
      const index = state.channels.findIndex(
        (channel) => channel.id === payload,
      );
      state.selectedChannel = state.channels[index];
    },
  },
});

export const { actions: channelsActions, reducer: channelsReducer } =
  channelsSlice;
export default channelsSlice.reducer;
