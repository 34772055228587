import { accountsActions } from "./slice/accounts.slice";
import { authActions } from "./slice/auth.slice";
import { channelsActions } from "./slice/channels.slice";
import { companyActions } from "./slice/company.slice";
import { documentsActions } from "./slice/documents.slice";
import { draftActions } from "./slice/draft.slice";
import { fileActions } from "./slice/file.slice";
import { productsActions } from "./slice/products.slice";
import { serviceActions } from "./slice/service.slice";
import { ssoActions } from "./slice/sso.slice";
import { subscriptionsActions } from "./slice/subscriptions.slice";
import { tableStatesActions } from "./slice/tableStates.slice";
import { tariffsActions } from "./slice/tariffs.slice";
import { tenantsActions } from "./slice/tenants.slice";
import { usersActions } from "./slice/users.slice";
import { uuidActions } from "./slice/uuid.slice";

export const rootActions = {
  ...channelsActions,
  ...authActions,
  ...fileActions,
  ...draftActions,
  ...usersActions,
  ...serviceActions,
  ...uuidActions,
  ...ssoActions,
  ...subscriptionsActions,
  ...accountsActions,
  ...tenantsActions,
  ...tableStatesActions,
  ...companyActions,
  ...documentsActions,
  ...productsActions,
  ...tariffsActions,
  logOut: () => (dispatch) => {
    dispatch(authActions.logOut());
    dispatch(channelsActions.logOut());
    dispatch(usersActions.logOut());
    dispatch(draftActions.logOut());
    dispatch(serviceActions.logOut());
    dispatch(fileActions.delAllFile());
    dispatch(serviceActions.resetAllAlertMessage());
    dispatch(uuidActions.logOut());
    dispatch(ssoActions.logOut());
    dispatch(subscriptionsActions.logOut());
    dispatch(accountsActions.logOut());
    dispatch(tenantsActions.logOut());
    dispatch(tableStatesActions.logOut());
    dispatch(companyActions.logOut());
    dispatch(documentsActions.logOut());
    dispatch(productsActions.logOut());
    dispatch(tariffsActions.logOut());
  },
};
