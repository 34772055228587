import { useState } from "react";

import { Button, Card, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomDatepicker from "../../components/Fields/CustomDatepicker";
import CustomTextField from "../../components/Fields/CustomTextField";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import { $authHost } from "../../http";
import { TitleStack } from "../../theme/standarts_styles";

const MyAccountsAdd = ({ handleClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.post(`/invoice/create`, data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/my-accounts");
  };

  const handleReset = () => {
    reset();
  };

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {t("src.pages.MyAccountsAdd.title")}
        </Typography>
      </TitleStack>
      <Card sx={{ mb: 2, p: 3 }}>
        {loading && <LoadingSpinner />}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CustomTextField label="AccountID" name="AccountID" required />
            <CustomTextField
              label="SubscriptionID"
              name="SubscriptionID"
              required
            />
            <CustomTextField
              label={t("src.pages.MyAccountsAdd.name")}
              name="AccountName"
              required
            />
            <CustomTextField
              label={t("src.pages.MyAccountsAdd.amount")}
              name="TotalAmount"
              required
            />
            <CustomTextField
              label={t("src.pages.MyAccountsAdd.state")}
              name="state"
            />
            <CustomAutocomplete
              label={t("src.pages.MyAccountsAdd.payment")}
              name="payment"
              options={["1", "2", "3"]}
            />
            <CustomAutocomplete
              label={t("src.pages.MyAccountsAdd.currency")}
              name="CurrencyCode"
              options={["USD", "KZT", "RUB"]}
              required
            />
            <CustomDatepicker
              label={t("src.pages.MyAccountsAdd.date")}
              name="InvoiceDate"
              required
            />
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <Button type="submit" variant="contained">
                  {t("main.another.save")}
                </Button>
                <Button onClick={handleBack} variant="text">
                  {t("main.another.back")}
                </Button>
              </Stack>
              <Button onClick={handleReset} variant="text">
                {t("main.another.reset")}
              </Button>
            </Stack>
          </form>
        </FormProvider>
        {/*<BlockFormModal when={isDirty && !isSubmitSuccessful} />*/}
      </Card>
    </Container>
  );
};

export default MyAccountsAdd;
