import { useCallback, useEffect, useRef, useState } from "react";

import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Avatar, Box, Button, Card, Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { acceptFileType } from "../../constants";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import AlertMessage from "../Alert/AlertMessage";
import CustomTextField from "../Fields/CustomTextField";
import LoadingSpinner from "../modals/loadingSpinner";

const UserData = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);
  const [uploadedAvatarLabel, setUploadedAvatarLabel] = useState(null);
  const { last_name, email, avatar, name } = useSelector(
    (state) => state.authReducer,
  );

  const { setErrorAlertMessage, updateUser } = useActions();

  const methods = useForm({
    mode: "onBlur",
    defaultValues: {
      email,
      name,
      last_name,
    },
  });
  const {
    handleSubmit,
    formState: { isDirty, isSubmitSuccessful },
    reset,
  } = methods;

  const inputRef = useRef(null);

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (
      fileObj &&
      acceptFileType.indexOf(fileObj.type) !== -1 &&
      fileObj.size / 1024 ** 2 < 3.6
    ) {
      const FR = new FileReader();
      FR.addEventListener("load", function (evt) {
        setUploadedAvatar(evt.target.result);
      });
      FR.readAsDataURL(event.target.files[0]);
      setUploadedAvatarLabel(fileObj.name);
    } else {
      setErrorAlertMessage(t("src.pages.AccountPage.UserData.extension"));
    }
  };

  const cleanInput = () => {
    setUploadedAvatar(null);
    setUploadedAvatarLabel(null);
  };

  const loadData = useCallback(async () => {
    try {
      // const response = await $authHost.get(`/user/find_one/${userId}`)
      const response = await $authHost.get(`/user/me`);
      const {
        last_name,
        email,
        avatar,
        name,
        roles,
        status,
        locked_flags,
        groups,
      } = response.data;
      updateUser({
        last_name,
        email,
        avatar,
        name,
        roles,
        status,
        locked_flags,
        groups,
      });
    } catch (e) {
      console.log(e);
    }
  }, [updateUser]);

  const onSubmit = async (data) => {
    setLoading(true);
    let update_data = data;
    // update_data['id'] = userId
    if (uploadedAvatar !== null) {
      update_data["avatar"] = uploadedAvatar;
    } else {
      update_data["avatar"] = avatar;
    }
    try {
      await $authHost.patch(`/user/me/update`, update_data);
      loadData();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      cleanInput();
      // document.getElementById('button_send').disabled = true
    }
  };

  // useEffect(() => {
  //   loadData();
  // }, [loadData]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        email,
        name,
        last_name,
      });
    }
  }, [email, name, last_name, isSubmitSuccessful, reset]);

  return (
    <Box sx={{ flexDirection: "column", px: 0, py: 4 }}>
      {loading && <LoadingSpinner />}
      <AlertMessage />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ width: "100%", p: 4 }}>
            <Grid
              alignItems="center"
              container
              direction="row"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                sx={{ mb: 2, pr: 2 }}
                xl={3}
                xs={12}
              >
                <div className="containerImg">
                  <Avatar
                    alt="photoURL"
                    id="img"
                    src={uploadedAvatar || avatar}
                    sx={{ width: 200, height: 200, mb: 2 }}
                  />
                  <input
                    id="avatar"
                    onChange={handleFileChange}
                    ref={inputRef}
                    style={{ display: "none" }}
                    type="file"
                  />
                  <div
                    className="overlay"
                    onClick={() => {
                      inputRef.current.click();
                    }}
                  >
                    <IconButton aria-label="delete" className="icon">
                      <DriveFolderUploadIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                </div>
                {uploadedAvatarLabel && (
                  <Chip
                    label={uploadedAvatarLabel}
                    onDelete={cleanInput}
                    variant="outlined"
                  />
                )}
              </Grid>
              <Grid item lg={9} md={9} sm={12} xl={9} xs={12}>
                <CustomTextField
                  label={t("src.pages.AccountPage.UserData.email")}
                  name="email"
                  required
                  rules={{
                    required: t("main.another.requiredField"),
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: t("src.pages.AccountPage.UserData.wrongEmail"),
                    },
                  }}
                />
                <CustomTextField
                  label={t("src.pages.AccountPage.UserData.name")}
                  name="name"
                  required
                />
                <CustomTextField
                  label={t("src.pages.AccountPage.UserData.last_name")}
                  name="last_name"
                  required
                />
              </Grid>
            </Grid>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Button
                color="primary"
                disabled={!isDirty && !uploadedAvatar}
                id="button_send"
                type="submit"
                variant="contained"
              >
                {t("main.another.submitButton", "Сохранить изменения")}
              </Button>
              <Button
                disabled={!isDirty}
                onClick={() => reset()}
                variant="text"
              >
                {t("main.another.reset", "Сбросить")}
              </Button>
            </Stack>
          </Card>
        </form>
      </FormProvider>
    </Box>
  );
};

export default UserData;
