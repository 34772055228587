import React from "react";

import "../../index.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import jwt_decode from "jwt-decode";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import { useAuth } from "../../hook/useAuth";
import { $authHost, $customHost } from "../../http";
import { authUrl } from "../../http/baseRoute";

const TimeoutWarning = ({ isOpen, onRequestClose }) => {
  const { setToken } = useActions();
  const { refresh_token } = useSelector((state) => state.authReducer);

  const { t } = useTranslation();
  const { signOut } = useAuth();
  const { isDarkTheme } = useSelector((state) => state.serviceReducer);

  const handleRefreshToken = async () => {
    try {
      const response = await $customHost.post(`${authUrl}/auth/refresh_token`, {
        refresh_token,
      });

      const {
        data: { access_token },
        headers: { date },
      } = response;

      const utcResponseTime = new Date(date);
      const startTime = utcResponseTime.getTime(); //localTimeMilliseconds

      const decodedToken = jwt_decode(access_token);
      const tokenLifetime =
        decodedToken.exp * 1000 - (Date.parse(date) || Date.now());

      setToken({ ...response.data, startTime, tokenLifetime });
      onRequestClose();
    } catch (e) {
      console.log(e);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "550px",
      width: "100%",
      padding: "30px",
      fontSize: "16px",
      backgroundColor: isDarkTheme ? "#272931" : "#ffffff",
      border: "none",
    },
    overlay: {
      position: "fixed",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      backgroundColor: "rgba(0,0,0,0.6)",
      display: "flex",
      zIndex: "10000",
    },
  };

  return (
    <div>
      <Modal isOpen={isOpen} style={customStyles}>
        <Typography sx={{ mb: 2 }} variant="h5">
          {t("src.components.modals.TimeoutWarning.header")}
        </Typography>
        <Typography variant="body1">
          {t("src.components.modals.TimeoutWarning.timeWarning")}
        </Typography>
        <Typography variant="body1">
          {t(
            "src.components.modals.TimeoutWarning.reauthorize",
            "Советуем переавторизоваться во избежании потери данных.",
          )}
        </Typography>
        <br />
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={0}
        >
          <Button onClick={() => signOut()} variant="text">
            {t("src.components.modals.TimeoutWarning.logoutNow")}
          </Button>
          <div>
            <Button
              onClick={handleRefreshToken}
              sx={{ marginLeft: "auto", mx: 2 }}
              variant="contained"
            >
              {t(
                "src.components.modals.TimeoutWarning.extendSession",
                "Продлить сессию",
              )}
            </Button>
            <Button onClick={onRequestClose} variant="text">
              {t("src.components.modals.TimeoutWarning.ok")}
            </Button>
          </div>
        </Stack>
      </Modal>
    </div>
  );
};

export default React.memo(TimeoutWarning);
