import React, {useState} from "react";

import "./event_styles.css";
import Logo from "./assets/aspex.png";
import speaker6 from "./assets/Media.jpeg";
import speaker2 from "./assets/speaker2.png";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CustomTextField from "../../../components/Fields/CustomTextField";
import {Button, Typography} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {$authHost, $host} from "../../../http";

const Landing10Event = () => {
  document.title = 'IT Вебинар 10.04';

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);

    try {
      await axios.post(`https://my-bk-dev.aspex.cloud/mailchimp/marketing/subscribe/big-data-ai`, data);
      setSuccess(true);
    } catch (e) {
      setError(
        "Произошла ошибка. Пожалуйста, проверьте введенные данные и повторите попытку.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="body">
      <header>
        <img
          src={Logo}
          style={{
            width: "10vw",
            maxWidth: "200px",
            minWidth: "70px"
          }}
        />
        <p>Эксклюзивный вебинар для технических специалистов: Big Data & AI в действии | 10 апреля 2025</p>
      </header>

      <div className="container">
        <div className="content">
          <div className="main-content">
            <h2 style={{ marginTop: "0px" }}>Big Data & AI: Управляем хаосом данных</h2>
            <p>Как структурировать данные и подготовить их для ИИ</p>
            <p>
              <strong>Дата и время: </strong>
              10 апреля 2025, 14:00 (МСК)
            </p>
            <p>
              <strong>Место: </strong>
              Zoom
            </p>
            <a className="register-btn" href="#reg">
              Зарегистрироваться
            </a>
          </div>
          <div className="sidebar">
            <h3>Что вас ждет?</h3>
            <ul>
              <li>Глубокий разбор работы с Big Data и AI</li>
              <li>Практические кейсы от экспертов</li>
              <li>Советы по оптимизации данных для ИИ</li>
              <li>Ответы на ваши вопросы</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="schedule">
        <h2>Спикеры и темы</h2>
        <div className="schedule-list">
          <div className="topic">
            <div className="info">
              <p>
                <strong>
                  {" "}
                  Arenadata: Как собрать хаос больших данных в единую
                  управляемую экосистему
                </strong>
              </p>
            </div>
            <div style={{ textAlign: "start" }}>
              <div className="speaker2">
                <img alt="Василий Демин" src={speaker6} />
                <div>
                  <h4>Василий Демин</h4>
                  <p>Product Marketing Team Lead, Arenadata</p>
                </div>
              </div>
            </div>
          </div>

          <div className="topic">
            <div className="info">
              <p>
                <strong>
                  {" "}
                  Подготовка и использования корпоративных данных в работе с ИИ.
                  Нюансы и частые проблемы.
                </strong>
              </p>
            </div>
            <div style={{ textAlign: "start" }}>
              <div className="speaker2">
                <img alt=">Вадим Тигин" src={speaker2} />
                <div>
                  <h4>Вадим Тигин</h4>
                  <p>CTO Aspex </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="feedback" id="reg">
        <div className="header_form">
          <h3>
            Количество мест ограничено!
          </h3>
          <h2>
            Оставьте заявку, чтобы получить ссылку на вебинар.
          </h2>
        </div>
        <div className="container2">
          <div className="content2">
            <div className="main-content2">
              <FormProvider {...methods}>
                {loading && <LoadingIndicator />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CustomTextField
                    label="Ваше имя"
                    name="first_name"
                    required
                  />
                  <CustomTextField
                    label="Электронная почта"
                    name="email"
                    required
                    rules={{
                      required: t("main.another.requiredField"),
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: t("main.another.wrongInput"),
                      },
                    }}
                  />
                  <CustomTextField
                    label="Компания"
                    name="company_name"
                    required
                  />
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    Зарегистрироваться
                  </Button>
                  {error && (
                    <Typography color="error" pt={2} variant="body2">
                      {error}
                    </Typography>
                  )}
                  {success && (
                    <Typography color="success" pt={2} variant="body2">
                      Ваши данные отправлены. Большое спасибо за регистрацию!
                    </Typography>
                  )}
                </form>
              </FormProvider>
              {/*<form*/}
              {/*  action="https://aspex.us10.list-manage.com/subscribe/post?u=8c33c566030559b7e6f051a71&amp;id=cbaf0df621&amp;f_id=005147e4f0"*/}
              {/*  method="post"*/}
              {/*>*/}
              {/*  <input name="EMAIL" placeholder="Введите email" required type="email" />*/}
              {/*  <input name="FNAME" placeholder="Имя" required type="text" />*/}
              {/*  <input name="MMERGE2" placeholder="Компания" required type="text"  />*/}
              {/*  <button type="submit">Зарегистрироваться</button>*/}
              {/*</form>*/}
            </div>
            <div className="sidebar2">
              <h3>Почему стоит прийти?</h3>
              <ul>
                <li>Как на практике организовать управляемую экосистему больших данных без хаоса</li>
                <li>Оптимальные подходы к подготовке корпоративных данных для AI</li>
                <li>Разбор ключевых проблем и подводных камней при работе с Big Data & AI</li>
                <li>Живые примеры и технические нюансы от экспертов отрасли</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="contacts">
        <h2>Контакты</h2>
        <p>Готовы внедрять ИИ и Big Data?</p>
        <p>Напишите нам – обсудим решения для вашего бизнеса</p>
        <p>info@aspex.kz</p>
      </section>
      <footer>&copy; ASPEX Group 2016-2025. All Rights Reserved.</footer>
    </div>
  );
};

export default Landing10Event;
