import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  settings: {},
};

const ssoSlice = createSlice({
  name: "sso",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setSettings(state, { payload }) {
      state.settings = payload;
    },
  },
});

export const { actions: ssoActions, reducer: ssoReducer } = ssoSlice;
export default ssoSlice.reducer;
