import { useCallback, useEffect, useState } from "react";

import { Button, Card, Grid, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AccessSsoSettingsFields from "../../components/Forms/FormFields/AccessSsoSettingsFields";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const AccessSsoSettingsPage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { setSettings } = useActions();

  const { settings } = useSelector((state) => state.ssoReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    reset(settings);
  }, [reset, settings]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await $authHost.get(`/sso/`);
      setSettings(data);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setSettings]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.patch(`/sso/update`, data);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card sx={{ p: 3, mb: 2, mt: 2 }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AccessSsoSettingsFields />
          <Grid item sx={{ width: "100%", mb: 2 }}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item>
                <Button disabled={loading} type="submit" variant="contained">
                  {t("main.another.save")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => reset()}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  {t("src.components.Intelligence.reset")}
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
    </Card>
  );
};

export default AccessSsoSettingsPage;
