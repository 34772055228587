import { useState } from "react";

import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getRoutes } from "../../routes";
import { SearchInput, StyledLink } from "../../theme/standarts_styles";

const SearchFieldInAppBar = ({ width }) => {
  const [inputValue, setInputValue] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const { roles } = useSelector((state) => state.authReducer);
  const navigate = useNavigate();
  const routes = getRoutes();

  const searchList = [];

  const forEachArr = (arr) => {
    arr.forEach((item) => {
      if (
        item.searchText &&
        roles.some((role) => item.roles?.some((r) => r.name === role.name))
      ) {
        searchList.push(item);
      }
      if (Array.isArray(item.children)) {
        forEachArr(item.children);
      }
    });
  };

  forEachArr(routes);

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SearchInput
          blurOnSelect={true}
          forcePopupIcon={false}
          getOptionLabel={(option) => option.searchText}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option.searchText === value}
          noOptionsText="Нет доступных вариантов"
          onChange={(event, newValue) => {
            if (newValue) {
              navigate(newValue.path);
            }
            setAutocompleteValue(null);
            setInputValue("");
          }}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          options={searchList}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                // '&::placeholder': {
                // 	color: '#8d8d8d',
                // },
                ...params.InputProps,
              }}
              placeholder="Search..."
              type="search"
              variant="outlined"
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledLink to={`/${option.path}`}>
                {option.searchText}
              </StyledLink>
            </li>
          )}
          size="small"
          sx={{
            width: `${width}`,
          }}
          value={autocompleteValue}
        />
      </div>
    </>
  );
};

export default SearchFieldInAppBar;
