import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { RequireRedirect } from "./requireRedirect";

const RequireAuth = ({ children }) => {
  const { access_token } = useSelector((state) => state.authReducer);

  if (!access_token) {
    return <Navigate to="/login" />;
  }

  return <RequireRedirect>{children}</RequireRedirect>;
};

export { RequireAuth };
