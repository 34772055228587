import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { matchIsValidTel } from "mui-tel-input";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import chatbotImage from "./assets/images/chatbot.png";
import chipImage from "./assets/images/chip.png";
import emailImage from "./assets/images/email.png";
import Image from "./assets/images/img.jpg";
import logoBlack from "./assets/images/logoBlack.png";
import logoGT from "./assets/images/logoGT.png";
import microsoft from "./assets/images/microsoft.png";
import robotImageDouble from "./assets/images/robotDouble.webp";
import photoVT from "./assets/images/VT_2.jpg";
import photoYG from "./assets/images/YG_2.png";
import CustomAutocomplete from "../../../components/Fields/CustomAutocomplete";
import CustomMuiTelInput from "../../../components/Fields/CustomMuiTelInput";
import CustomTextField from "../../../components/Fields/CustomTextField";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { Item_block } from "../../../theme/standarts_styles";
import bllogo from "../Leadmagnet/assets/images/blLogo.png";
import "../land_styles.css";

const LandingVideo = () => {
  document.title = "Вебинар ASPEX AI + GETCOURSE";
  document.description =
    "Первый в СНГ вебинар посвященный интеграции искусственного интеллекта в платформу GETCOURSE";
  document.image = Image;

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [isTablet, setIsTablet] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);
    data.tag = "с лендинга записи вебинара июнь 24";
    try {
      await axios.post(
        `https://my-bk-dev.aspex.cloud/mailchimp/webinar/recording`,
        data,
      );
      setSuccess(true);
    } catch (e) {
      setError(
        "Произошла ошибка. Пожалуйста, проверьте введенные данные и повторите попытку.",
      );
    } finally {
      setLoading(false);
    }
  };

  const onResizeHandler = useCallback(() => {
    const isTablet = window.innerWidth >= 0 && window.innerWidth <= 900;
    const isMobile = window.innerWidth <= 600;
    setIsTablet(isTablet);
    setIsMobile(isMobile);
  }, []);

  const speakers = [
    {
      photo: photoYG,
      name: "Юлия Гайнутдинова",
      description:
        "Основатель и генеральный директор компании ASPEX - ведущего профессионального IT интегратора и эксперта в области BI-аналитики со статусом Золотого партнера Microsoft",
    },
    {
      photo: photoVT,
      name: "Вадим Тигин",
      description:
        "Технический директор компании ASPEX, директор продукта в области ИИ ASPEX AI и руководитель направления Aspex AI Online Education",
    },
  ];

  const benefits = [
    {
      logo: chatbotImage,
      title: "ЗАДАВАЙТЕ ВОПРОСЫ ЭКСПЕРТАМ",
      description:
        "Получите ответы на любые вопросы от технического и генерального директоров компании Aspex, золотого партнера Microsoft с многолетним опытом и более 370 успешных проектов для бизнеса.",
    },
    {
      logo: chipImage,
      title: "БЕСПЛАТНАЯ КОНСУЛЬТАЦИЯ",
      description:
        "Уникальная возможность получить бесплатную консультацию с нашими экспертами о внедрении ИИ конкретно в ваш бизнес!",
    },
    {
      logo: emailImage,
      title: "СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ",
      description:
        "Только для первых 20 участников - эксклюзивное предложение на внедрение и поддержку ИИ!",
    },
  ];

  const setup = useCallback(() => {
    window.addEventListener("resize", onResizeHandler, false);
  }, [onResizeHandler]);

  const cleanup = useCallback(() => {
    window.removeEventListener("resize", onResizeHandler, false);
  }, [onResizeHandler]);

  useEffect(() => {
    onResizeHandler();

    setup();

    return () => {
      cleanup();
    };
  }, [cleanup, setup, onResizeHandler]);

  return (
    <Grid
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      sx={{ backgroundColor: "#fff" }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#6e6cd9",
          py: { xl: "50px", lg: "50px", md: "50px", sm: "20px", xs: "40px" },
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "25px" },
        }}
      >
        <Typography
          align="left"
          sx={{
            color: "#ffffff",
            fontSize: {
              xl: "50px",
              lg: "50px",
              md: "50px",
              sm: "40px",
              xs: "40px",
            },
            fontWeight: 700,
          }}
        >
          ПЕРВЫЙ В СНГ ВЕБИНАР
          <br />
        </Typography>
        <Typography
          align="left"
          sx={{
            color: "#ffffff",
            fontSize: {
              xl: "30px",
              lg: "30px",
              md: "30px",
              sm: "20px",
              xs: "22px",
            },
            fontWeight: 600,
          }}
        >
          ПОСВЯЩЕННЫЙ ИНТЕГРАЦИИ ИСКУССТВЕННОГО ИНТЕЛЛЕКТА В ПЛАТФОРМУ
          GETCOURSE!
        </Typography>
      </Grid>

      <Grid container spacing={0} sx={{ width: "100%" }}>
        {isTablet === false ? (
          <>
            <Grid
              item
              lg={6}
              md={4}
              pb={{ xs: 12, md: 0 }}
              pt={12}
              sm={0}
              sx={{
                height: "80vh",
                backgroundImage: `url(${robotImageDouble})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                position: "relative",
              }}
              xl={6}
              xs={0}
            ></Grid>
            <Grid
              alignItems="center"
              direction="column"
              item
              justifyContent="center"
              lg={6}
              md={8}
              sm={0}
              sx={{
                width: "100%",
                display: "flex",
                px: {
                  xl: "0px",
                  lg: "0px",
                  md: "50px",
                  sm: "15px",
                  xs: "15px",
                },
              }}
              xl={6}
              xs={0}
            >
              <Grid
                alignItems="center"
                direction="column"
                item
                justifyContent="center"
                mb={2}
                mt={2}
                sx={{ display: "flex" }}
                xs={12}
              >
                <Stack
                  spacing={4}
                  sx={{ alignItems: "center", marginBottom: "20px" }}
                >
                  <img
                    alt=""
                    src={logoBlack}
                    style={{ width: "400px", height: "auto" }}
                  />
                  <Typography
                    px={2}
                    style={{ fontSize: "40px", fontWeight: 700, lineHeight: 0 }}
                  >
                    +
                  </Typography>
                  <img
                    alt=""
                    src={logoGT}
                    style={{ width: "400px", height: "auto" }}
                  />
                </Stack>
                <Grid item mt={5}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "50px",
                      fontWeight: 700,
                      textTransform: "uppercase",
                    }}
                  >
                    СОЗДАЙТЕ ПРИБЫЛЬНУЮ
                    <br /> ОНЛАЙН-ШКОЛУ
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            alignItems="center"
            direction="column"
            item
            justifyContent="flex-end"
            pb={4}
            sx={{
              backgroundImage: `url(${robotImageDouble})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "bottom",
              position: "relative",
              height: "90vh",
              display: "flex",
            }}
            xs={12}
          >
            <Grid
              alignItems="center"
              container
              direction="column"
              px={4}
              py={1}
              sx={{
                width: "100%",
                backgroundColor: "rgba(255,255,255, 1)",
              }}
            >
              <Stack
                spacing={3}
                sx={{
                  alignItems: "center",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                <img alt="" className="black_logo_top" src={logoBlack} />
                <Typography
                  px={2}
                  style={{ fontSize: "40px", fontWeight: 600, lineHeight: 0 }}
                >
                  +
                </Typography>
                <img alt="" className="gt_logo_top" src={logoGT} />
              </Stack>
              <Grid item>
                <Typography
                  align="center"
                  sx={{
                    fontSize: {
                      xl: "50px",
                      lg: "50px",
                      md: "50px",
                      sm: "40px",
                      xs: "30px",
                    },
                    fontWeight: 700,
                    textTransform: "uppercase",
                  }}
                >
                  СОЗДАЙТЕ ПРИБЫЛЬНУЮ
                  <br /> ОНЛАЙН-ШКОЛУ
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        sx={{
          width: "100%",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
          py: { xl: "100px", lg: "100px", md: "50px", sm: "20px", xs: "40px" },
        }}
      >
        <Typography
          sx={{ fontSize: "40px", fontWeight: 700, textTransform: "uppercase" }}
        >
          СПИКЕРЫ
        </Typography>
        <Box alignItems="center" my={5} sx={{ display: "flex" }}>
          <Box>
            <img src={microsoft} style={{ width: "auto", height: "60px" }} />
          </Box>
        </Box>
        <Stack
          direction={{ xs: "column", xl: "row", lg: "row" }}
          my={3}
          spacing={4}
        >
          {speakers.map((speaker) => (
            <>
              {isMobile === true ? (
                <Card
                  sx={{
                    width: {
                      xl: "calc(100%/2)",
                      lg: "calc(100%/2)",
                      xs: "100%",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={speaker.photo}
                    sx={{ width: "100%", height: "auto" }}
                  />
                  <CardContent>
                    <Typography component="div" sx={{ fontSize: "28px" }}>
                      {speaker.name}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{ fontSize: "18px" }}
                    >
                      {speaker.description}
                    </Typography>
                  </CardContent>
                </Card>
              ) : (
                <Card
                  sx={{
                    display: "flex",
                    width: {
                      xl: "calc(100%/2)",
                      lg: "calc(100%/2)",
                      xs: "100%",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    image={speaker.photo}
                    sx={{ width: 150, height: "auto" }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Typography component="div" sx={{ fontSize: "28px" }}>
                        {speaker.name}
                      </Typography>
                      <Typography
                        color="text.secondary"
                        component="div"
                        sx={{ fontSize: "18px" }}
                      >
                        {speaker.description}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              )}
            </>
          ))}
        </Stack>
      </Grid>

      <Grid
        alignItems="flex-start"
        container
        direction={{ xl: "row", lg: "row", xs: "row" }}
        justifyContent="center"
        spacing={0}
        sx={{
          width: "100%",
          backgroundColor: "#6e6cd9",
        }}
      >
        {/*<Grid*/}
        {/*    item*/}
        {/*    xs={12} xl={6} lg={6} md={12} sm={12}*/}
        {/*  sx={{*/}
        {/*      display: 'flex',*/}
        {/*      // backgroundColor:'#6e6cd9',*/}
        {/*  }}*/}
        {/*  direction="column" alignItems='center' justifyContent='center'*/}
        {/*  py={{xs: 8}}*/}
        {/*  px={{xs: 4}}*/}
        {/*>*/}
        {/*    /!*<Typography align="center" className="text_about"  mb={2}*!/*/}
        {/*    /!*            sx={{fontWeight: 700, textTransform: 'uppercase', color:'#fff'}}*!/*/}
        {/*    /!*>*!/*/}
        {/*    /!*    ПОЛУЧИТЕ ЗАПИСЬ НАШЕГО ЭКСКЛЮЗИВНОГО ВЕБИНАРА АБСОЛЮТНО БЕСПЛАТНО!*!/*/}
        {/*    /!*</Typography>*!/*/}
        {/*    <Typography align="center" className="dot_text"*/}
        {/*                sx={{fontWeight: 500, color:'#fff'}}*/}
        {/*    >*/}
        {/*        Оставьте нам свои контакты, чтобы получить ссылку на запись по почте*/}
        {/*    </Typography>*/}
        {/*    <Typography align="center" className="dot_text"*/}
        {/*                sx={{fontWeight: 500, color:'#fff'}}*/}
        {/*    >*/}
        {/*        Узнайте все секреты успеха с ИИ!*/}
        {/*    </Typography>*/}
        {/*    <Box sx={{maxWidth: '700px', position: 'relative'}}*/}
        {/*         pt={4}*/}
        {/*    >*/}
        {/*        {success ? (*/}
        {/*            <Box sx={{height: '324px', display: 'flex', alignItems: 'center'}}>*/}
        {/*                <Typography variant='h4' color="#ffffff">*/}
        {/*                    Запись отправлена! Пожалуйста, проверьте свою электронную почту — мы уже отправили вам письмо со ссылкой на запись.*/}
        {/*                </Typography>*/}
        {/*            </Box>*/}
        {/*        ) : (*/}
        {/*            <Card*/}
        {/*                sx={{*/}
        {/*                    padding: '25px',*/}
        {/*                    marginBottom: '20px'*/}
        {/*                  }}*/}
        {/*            >*/}
        {/*                <FormProvider {...methods}>*/}
        {/*                    {loading && <LoadingIndicator/>}*/}
        {/*                    <form onSubmit={handleSubmit(onSubmit)}>*/}
        {/*                        <CustomTextField*/}
        {/*                            name='first_name'*/}
        {/*                            label='Ваше имя'*/}
        {/*                            required*/}
        {/*                        />*/}
        {/*                        <CustomTextField*/}
        {/*                            name='email'*/}
        {/*                            label='Электронная почта'*/}
        {/*                            rules={{*/}
        {/*                                required: t('main.another.requiredField'),*/}
        {/*                                pattern: {*/}
        {/*                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,*/}
        {/*                                    message: t('main.another.wrongInput'),*/}
        {/*                                },*/}
        {/*                            }}*/}
        {/*                            required*/}
        {/*                        />*/}
        {/*                        <CustomMuiTelInput*/}
        {/*                            name="phone"*/}
        {/*                            label='Телефон'*/}
        {/*                            rules={{*/}
        {/*                                required: t('main.another.requiredField'),*/}
        {/*                                validate: {*/}
        {/*                                    validPhone: (phone) => {*/}
        {/*                                        if (!phone) {*/}
        {/*                                            return true;*/}
        {/*                                        } else if (!matchIsValidTel(phone)) {*/}
        {/*                                            return 'Некорректный номер телефона';*/}
        {/*                                        }*/}
        {/*                                        return true; // Возвращает true, если проверка прошла успешно*/}
        {/*                                    },*/}
        {/*                                },*/}
        {/*                            }}*/}
        {/*                            sx={{ width: '100%', fontSize: '12px', mb: 3 }}*/}
        {/*                            size="small"*/}
        {/*                        />*/}
        {/*                        <CustomTextField*/}
        {/*                            name='company_name'*/}
        {/*                            label='Название вашей школы'*/}
        {/*                            required*/}
        {/*                        />*/}
        {/*                        <CustomAutocomplete*/}
        {/*                            name='role'*/}
        {/*                            label='Ваша роль'*/}
        {/*                            options={['Основатель',*/}
        {/*                                'Продюсер',*/}
        {/*                                'Проджект-менеджер',*/}
        {/*                                'Куратор',*/}
        {/*                                'Руководитель отдела кураторов',*/}
        {/*                                'Педагог']}*/}
        {/*                            required*/}
        {/*                            freeSolo*/}
        {/*                        />*/}
        {/*                        <Button variant='contained' type='submit' color='primary'*/}
        {/*                                sx={{width: '100%'}}>Получить бесплатно</Button>*/}
        {/*                        {error && (*/}
        {/*                            <Typography pt={2} variant='body2' color='error'>{error}</Typography>*/}
        {/*                        )}*/}
        {/*                    </form>*/}
        {/*                </FormProvider>*/}
        {/*            </Card>*/}
        {/*        )}*/}
        {/*    </Box>*/}

        {/*</Grid>*/}
        <Grid
          alignItems={{ xl: "flex-end", lg: "flex-end", xs: "center" }}
          direction="column"
          item
          justifyContent="flex-start"
          lg={6}
          md={12}
          pb={{ xl: 8, lg: 8, md: 1, xs: 1 }}
          pt={{ xl: 8, lg: 8, md: 2, xs: 2 }}
          px={{ xs: 4 }}
          sm={12}
          sx={{
            display: "flex",
          }}
          xl={6}
          xs={12}
        >
          <Typography
            mb={2}
            pt={4}
            sx={{
              textTransform: "uppercase",
              color: "#fff",
              textAlign: {
                xl: "right",
                lg: "right",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
            variant="h2"
          >
            ПОЛУЧИТЕ ЗАПИСЬ
            <br />
            НАШЕГО ЭКСКЛЮЗИВНОГО ВЕБИНАРА
            <br />
            АБСОЛЮТНО БЕСПЛАТНО!
          </Typography>
          <Typography
            // variant="h4"
            className="dot_text"
            sx={{
              fontWeight: 400,
              color: "#fff",
              textAlign: {
                xl: "right",
                lg: "right",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
          >
            Оставьте нам свои контакты,
            <br /> чтобы получить ссылку на запись по почте
          </Typography>
          <Typography
            align="right"
            // variant="h4"
            className="dot_text"
            sx={{
              fontWeight: 400,
              color: "#fff",
              textAlign: {
                xl: "right",
                lg: "right",
                md: "center",
                sm: "center",
                xs: "center",
              },
            }}
          >
            Узнайте все секреты успеха с ИИ!
          </Typography>
          {/*<Typography align="center" sx={{width: '100%', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase'}}>*/}
          {/*    Вы узнаете*/}
          {/*</Typography>*/}
          {/*<Stack*/}
          {/*    sx={{py:3}}*/}
          {/*    spacing={{ xs: 1, sm: 2, md:4, lg:3, xl:3 }}*/}
          {/*    direction="row"*/}
          {/*    useFlexGap*/}
          {/*    flexWrap="wrap"*/}
          {/*    justifyContent="center"*/}
          {/*    alignItems="center"*/}
          {/*>*/}
          {/*    <Paper elevation={3} sx={{padding: '12px'}}>*/}
          {/*        <Item_block sx={{fontSize: {xl: '20px', lg:'20px', md:'18px', sm:'16px', xs:'16px'}}}>*/}
          {/*            <span style={{fontWeight: 500}}>Как эффективно использовать ИИ</span> <br/>для создания прибыльной онлайн-школы*/}
          {/*        </Item_block>*/}
          {/*    </Paper>*/}
          {/*    <Paper sx={{padding: '12px'}} elevation={3}>*/}
          {/*        <Item_block sx={{fontSize: {xl: '20px', lg:'20px', md:'18px', sm:'16px', xs:'16px'}}}>*/}
          {/*            <span style={{fontWeight: 500}}> Варианты решений основных проблем</span> <br/>в организации онлайн-обучения с помощью ИИ*/}
          {/*        </Item_block>*/}
          {/*    </Paper>*/}
          {/*    <Paper sx={{padding: '12px'}} elevation={3}>*/}
          {/*        <Item_block sx={{fontSize: {xl: '20px', lg:'20px', md:'18px', sm:'16px', xs:'16px'}}}>*/}
          {/*            Прозрачные тарифы и<span style={{fontWeight: 500}}> преимущества готового решения</span><br/> с быстрым внедрением*/}
          {/*        </Item_block>*/}
          {/*    </Paper>*/}
          {/*    <Paper sx={{padding: '12px'}} elevation={3}>*/}
          {/*        <Item_block sx={{fontSize: {xl: '20px', lg:'20px', md:'18px', sm:'16px', xs:'16px'}}}>*/}
          {/*            <span style={{fontWeight: 500}}>  Реальные кейсы и примеры внедрения ИИ</span><br/> в образовательные процессы*/}
          {/*        </Item_block>*/}
          {/*    </Paper>*/}
          {/*    <Paper sx={{padding: '12px'}} elevation={3}>*/}
          {/*        <Item_block sx={{fontSize: {xl: '20px', lg:'20px', md:'18px', sm:'16px', xs:'16px'}}}>*/}
          {/*            <span style={{fontWeight: 500}}> Возможности и функции Aspex AI Lite</span> для оптимизации обучения <br/>и результат интеграции в Getcourse*/}
          {/*        </Item_block>*/}
          {/*    </Paper>*/}
          {/*</Stack>*/}
        </Grid>
        <Grid
          alignItems="center"
          direction="column"
          item
          justifyContent="center"
          lg={6}
          md={12}
          pb={{ xl: 8, lg: 8, md: 6, xs: 4 }}
          pt={{ xl: 8, lg: 8, md: 1, xs: 1 }}
          px={{ xs: 4 }}
          sm={12}
          sx={{
            display: "flex",
            // backgroundColor:'#6e6cd9',
          }}
          xl={6}
          xs={12}
        >
          {/*<Typography align="center" className="text_about"  mb={2}*/}
          {/*            sx={{fontWeight: 700, textTransform: 'uppercase', color:'#fff'}}*/}
          {/*>*/}
          {/*    ПОЛУЧИТЕ ЗАПИСЬ НАШЕГО ЭКСКЛЮЗИВНОГО ВЕБИНАРА АБСОЛЮТНО БЕСПЛАТНО!*/}
          {/*</Typography>*/}
          {/*<Typography align="center" className="dot_text"*/}
          {/*            sx={{fontWeight: 400, color:'#fff'}}*/}
          {/*>*/}
          {/*    Оставьте нам свои контакты, чтобы получить ссылку на запись по почте*/}
          {/*</Typography>*/}
          {/*<Typography align="center" className="dot_text"*/}
          {/*            sx={{fontWeight: 400, color:'#fff'}}*/}
          {/*>*/}
          {/*    Узнайте все секреты успеха с ИИ!*/}
          {/*</Typography>*/}
          <Box pt={4} sx={{ maxWidth: "700px", position: "relative" }}>
            {success ? (
              <Box
                sx={{ height: "324px", display: "flex", alignItems: "center" }}
              >
                <Typography color="#ffffff" variant="h4">
                  Запись отправлена! Пожалуйста, проверьте свою электронную
                  почту — мы уже отправили вам письмо со ссылкой на запись.
                </Typography>
              </Box>
            ) : (
              <Card
                sx={{
                  padding: "25px",
                  marginBottom: "20px",
                }}
              >
                <FormProvider {...methods}>
                  {loading && <LoadingIndicator />}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomTextField
                      label="Ваше имя"
                      name="first_name"
                      required
                    />
                    <CustomTextField
                      label="Электронная почта"
                      name="email"
                      required
                      rules={{
                        required: t("main.another.requiredField"),
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                          message: t("main.another.wrongInput"),
                        },
                      }}
                    />
                    <CustomMuiTelInput
                      label="Телефон"
                      name="phone"
                      rules={{
                        required: t("main.another.requiredField"),
                        validate: {
                          validPhone: (phone) => {
                            if (!phone) {
                              return true;
                            } else if (!matchIsValidTel(phone)) {
                              return "Некорректный номер телефона";
                            }
                            return true; // Возвращает true, если проверка прошла успешно
                          },
                        },
                      }}
                      size="small"
                      sx={{ width: "100%", fontSize: "12px", mb: 3 }}
                    />
                    <CustomTextField
                      label="Название вашей школы"
                      name="company_name"
                      required
                    />
                    <CustomAutocomplete
                      freeSolo
                      label="Ваша роль"
                      name="role"
                      options={[
                        "Основатель",
                        "Продюсер",
                        "Проджект-менеджер",
                        "Куратор",
                        "Руководитель отдела кураторов",
                        "Педагог",
                      ]}
                      required
                    />
                    <Button
                      color="primary"
                      sx={{ width: "100%" }}
                      type="submit"
                      variant="contained"
                    >
                      Получить бесплатно
                    </Button>
                    {error && (
                      <Typography color="error" pt={2} variant="body2">
                        {error}
                      </Typography>
                    )}
                  </form>
                </FormProvider>
              </Card>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        sx={{
          width: "100%",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
          py: { xl: "100px", lg: "100px", md: "50px", sm: "20px", xs: "40px" },
        }}
      >
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            textTransform: "uppercase",
            marginBottom: 5,
          }}
        >
          Вы узнаете
        </Typography>
        {/*<Typography align="center" sx={{width: '100%', fontSize: '30px', fontWeight: 700, textTransform: 'uppercase'}}>*/}
        {/*    Вы узнаете*/}
        {/*</Typography>*/}
        <Stack
          alignItems="center"
          direction="row"
          flexWrap="wrap"
          justifyContent="center"
          spacing={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4 }}
          sx={{ py: 3 }}
          useFlexGap
        >
          <Paper elevation={3} sx={{ padding: "12px" }}>
            <Item_block
              sx={{
                fontSize: {
                  xl: "20px",
                  lg: "20px",
                  md: "18px",
                  sm: "16px",
                  xs: "16px",
                },
              }}
            >
              <span style={{ fontWeight: 500 }}>
                Как эффективно использовать ИИ
              </span>{" "}
              <br />
              для создания прибыльной онлайн-школы
            </Item_block>
          </Paper>
          <Paper elevation={3} sx={{ padding: "12px" }}>
            <Item_block
              sx={{
                fontSize: {
                  xl: "20px",
                  lg: "20px",
                  md: "18px",
                  sm: "16px",
                  xs: "16px",
                },
              }}
            >
              <span style={{ fontWeight: 500 }}>
                {" "}
                Варианты решений основных проблем
              </span>{" "}
              <br />в организации онлайн-обучения с помощью ИИ
            </Item_block>
          </Paper>
          <Paper elevation={3} sx={{ padding: "12px" }}>
            <Item_block
              sx={{
                fontSize: {
                  xl: "20px",
                  lg: "20px",
                  md: "18px",
                  sm: "16px",
                  xs: "16px",
                },
              }}
            >
              Прозрачные тарифы и
              <span style={{ fontWeight: 500 }}>
                {" "}
                преимущества готового решения
              </span>
              <br /> с быстрым внедрением
            </Item_block>
          </Paper>
          <Paper elevation={3} sx={{ padding: "12px" }}>
            <Item_block
              sx={{
                fontSize: {
                  xl: "20px",
                  lg: "20px",
                  md: "18px",
                  sm: "16px",
                  xs: "16px",
                },
              }}
            >
              <span style={{ fontWeight: 500 }}>
                {" "}
                Реальные кейсы и примеры внедрения ИИ
              </span>
              <br /> в образовательные процессы
            </Item_block>
          </Paper>
          <Paper elevation={3} sx={{ padding: "12px" }}>
            <Item_block
              sx={{
                fontSize: {
                  xl: "20px",
                  lg: "20px",
                  md: "18px",
                  sm: "16px",
                  xs: "16px",
                },
              }}
            >
              <span style={{ fontWeight: 500 }}>
                {" "}
                Возможности и функции Aspex AI Lite
              </span>{" "}
              для оптимизации обучения <br />и результат интеграции в Getcourse
            </Item_block>
          </Paper>
        </Stack>

        {/*    <Typography sx={{fontSize: '40px', fontWeight: 700, textTransform: 'uppercase'}}>*/}
        {/*        СПИКЕРЫ*/}
        {/*    </Typography>*/}
        {/*    <Box sx={{display: 'flex'}} my={3} alignItems='center'>*/}
        {/*        <Box>*/}
        {/*            <img src={microsoft} style={{width: 'auto', height: '50px'}}/>*/}
        {/*        </Box>*/}
        {/*    </Box>*/}
        {/*    <Stack direction={{ xs: "column", xl:"row", lg: 'row'}} spacing={4}>*/}
        {/*        {speakers.map(speaker => (*/}
        {/*            <>*/}
        {/*                {*/}
        {/*                    isMobile === true ?*/}
        {/*                        <Card sx={{ width: {xl:'calc(100%/2)', lg:'calc(100%/2)',xs:'100%' }}}>*/}
        {/*                            <CardMedia*/}
        {/*                                component="img"*/}
        {/*                                sx={{ width: '100%', height: 'auto'}}*/}
        {/*                                image={speaker.photo}*/}
        {/*                            />*/}
        {/*                            <CardContent>*/}
        {/*                                <Typography component="div" sx={{fontSize: '28px'}}>*/}
        {/*                                    {speaker.name}*/}
        {/*                                </Typography>*/}
        {/*                                <Typography sx={{fontSize: '18px'}} color="text.secondary" >*/}
        {/*                                    {speaker.description}*/}
        {/*                                </Typography>*/}
        {/*                            </CardContent>*/}
        {/*                        </Card>*/}
        {/*                        :*/}
        {/*                        <Card sx={{ display: 'flex', width: {xl:'calc(100%/2)', lg:'calc(100%/2)',xs:'100%' }}}>*/}
        {/*                            <CardMedia*/}
        {/*                                component="img"*/}
        {/*                                sx={{ width: 150, height: 'auto'}}*/}
        {/*                                image={speaker.photo}*/}
        {/*                            />*/}
        {/*                            <Box sx={{ display: 'flex', flexDirection:  'column' }}>*/}
        {/*                                <CardContent sx={{ flex: '1 0 auto' }}>*/}
        {/*                                    <Typography component="div" sx={{fontSize: '28px'}}>*/}
        {/*                                        {speaker.name}*/}
        {/*                                    </Typography>*/}
        {/*                                    <Typography sx={{fontSize: '18px'}} color="text.secondary" component="div">*/}
        {/*                                        {speaker.description}*/}
        {/*                                    </Typography>*/}
        {/*                                </CardContent>*/}
        {/*                            </Box>*/}
        {/*                        </Card>*/}
        {/*                }*/}
        {/*            </>*/}
        {/*        ))}*/}
        {/*    </Stack>*/}
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        sx={{
          width: "100wh",
          backgroundColor: "#f3f3f3",
          pt: "30px",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
        }}
        width="100wh"
      >
        <Box
          component="img"
          src={bllogo}
          sx={{
            height: 20,
            mb: 4,
            mt: 3,
          }}
        />
        <Typography align="center" mb={1} variant="h6">
          Нужна помощь с ИИ? Свяжитесь с нами
        </Typography>
        <Typography mb={1} variant="h6">
          info@aspex.kz
        </Typography>
        <Typography align="center" mb={5} variant="body1">
          Copyright ASPEX Group 2016-2024. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LandingVideo;
