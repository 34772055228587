import React, { useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AlertMessage from "../../components/Alert/AlertMessage";
import CustomTextField from "../../components/Fields/CustomTextField";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const CompletionRegistrationForm = () => {
  const { t } = useTranslation();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowNewPassword = () =>
    setShowNewPassword((prevState) => !prevState);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((prevState) => !prevState);

  const methods = useForm({
    mode: "onBlur",
  });
  const { setError, control, handleSubmit } = methods;

  const newPassword = useWatch({
    control,
    name: "newPassword",
    defaultValue: "",
  });

  const { setErrorAlertMessage, setToken } = useActions();

  const { status } = useSelector((state) => state.authReducer);

  const onSubmit = async (data) => {
    const { newPassword, confirmPassword, ...restData } = data;
    const processedData = {
      ...restData,
      password: newPassword,
    };
    try {
      const response = await $authHost.patch(
        `/user/complete_registration`,
        processedData,
      );
      if (response.status === 201) {
        const utcResponseTime = new Date(response.headers["date"]);
        const startTime = utcResponseTime.getTime(); //localTimeMilliseconds
        const { access_token } = response.data;
        setToken({ access_token, startTime });
        // const { last_name, email, avatar, name, roles, status, locked_flags, access_token } = response.data
        // updateUser({ last_name, email, avatar, name, roles, status, locked_flags, access_token })
        // navigate('/budgets-and-advances')
      }
    } catch (e) {
      if (
        e.response.status === 401 &&
        e.response.data.detail ===
          "The old and new passwords must not be the same"
      ) {
        setError("newPassword", {
          type: "manual",
          message: t("src.pages.CompletionRegistration.differentPassword"),
        });
      }
    }
  };

  // useEffect(() => {
  //   if (status === 'Active') {
  //     return <Navigate to="/"/>
  //   }
  // }, [])

  return (
    <>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 2 }}
        >
          <Typography sx={{ mb: 1 }} variant="body2">
            {t("src.pages.CompletionRegistration.followingInformation")}
          </Typography>
          <CustomTextField
            autoComplete="given-name"
            label={t("src.pages.AccountPage.UserData.name")}
            name="name"
            required
          />
          <CustomTextField
            autoComplete="family-name"
            label={t("src.pages.AccountPage.UserData.last_name")}
            name="last_name"
            required
          />
          <Typography sx={{ mb: 1 }} variant="body2">
            {t("src.pages.CompletionRegistration.changePassword")}
          </Typography>
          <CustomTextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowNewPassword}
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t("src.pages.AccountPage.ChangePassword.newPassword")}
            name="newPassword"
            required
            rules={{
              pattern: {
                value: /^[!-~]{1,32}$/i,
                message: t(
                  "src.pages.AccountPage.ChangePassword.uncorrectedError",
                ),
              },
            }}
            secondErrorText={t(
              "src.pages.AccountPage.ChangePassword.uncorrectedError",
            )}
            type={showNewPassword ? "text" : "password"}
          />
          <CustomTextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={handleClickShowConfirmPassword}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={t("src.pages.AccountPage.ChangePassword.confirmPassword")}
            name="confirmPassword"
            required
            rules={{
              validate: (value) => {
                return (
                  value === newPassword ||
                  t("src.pages.AccountPage.ChangePassword.validPassword")
                );
              },
            }}
            type={showConfirmPassword ? "text" : "password"}
          />
          <Button fullWidth sx={{ mb: 2 }} type="submit" variant="contained">
            {t("main.another.save")}
          </Button>
          <AlertMessage />
        </Box>
      </FormProvider>
    </>
  );
};

export default CompletionRegistrationForm;
