import { useMemo } from "react";

import { CssBaseline } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useSelector } from "react-redux";

import customShadows from "./customShadows";
import darkCustomShadows from "./darkTheme/darkCustomShadows";
import darkPalette from "./darkTheme/darkPalette";
import darkShadows from "./darkTheme/darkShadows";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";

export default function ThemeProvider({ children }) {
  const { isDarkTheme } = useSelector((state) => state.serviceReducer);
  let theme;

  const lightThemeOptions = useMemo(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
    }),
    [],
  );
  const darkThemeOptions = useMemo(
    () => ({
      palette: darkPalette,
      shape: { borderRadius: 6 },
      typography,
      shadows: darkShadows(),
      customShadows: darkCustomShadows(),
    }),
    [],
  );

  const lightTheme = createTheme(lightThemeOptions);
  lightTheme.components = componentsOverride(lightTheme);

  const darkTheme = createTheme(darkThemeOptions);
  darkTheme.components = componentsOverride(darkTheme);

  switch (isDarkTheme) {
    case true:
      theme = darkTheme;
      break;
    case false:
      theme = lightTheme;
      break;
    default:
      theme = lightTheme;
  }

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
