import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomDatepicker from "../../components/Fields/CustomDatepicker";
import CustomTextField from "../../components/Fields/CustomTextField";
import Footer from "../../components/Footer";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const AzureChangeTariffFixed = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { subscriptionId } = useParams();

  const { subscription } = useSelector((state) => state.subscriptionsReducer);
  const { tariffs } = useSelector((state) => state.tariffsReducer);
  const { setSubscription, setTariffs } = useActions();

  const methodsOldTariff = useForm({
    mode: "onBlur",
    defaultValues: subscription,
  });

  const methodsNewTariff = useForm({
    mode: "onBlur",
  });

  const { handleSubmit, control, setValue } = methodsNewTariff;

  const onSubmit = async ({ DomainPrefix, ...data }) => {
    setLoading(true);
    try {
      // await $customHost.post(`https://auth.aspex.cloud/auth/sign-up`, newData);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/subscription/get_one/${subscriptionId}`,
      );
      setSubscription(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setSubscription, subscriptionId]);

  const getTariffs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/tariff/get_all?tariff_type=Tariff`,
      );
      setTariffs(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setTariffs]);

  useEffect(() => {
    getSubscription();
    getTariffs();
  }, [getSubscription, getTariffs]);

  const watchTariff = useWatch({ control, name: "tariff" });

  useEffect(() => {
    if (watchTariff && watchTariff?.TariffDescription) {
      setValue("tariff_description", watchTariff?.TariffDescription);
    } else {
      setValue("tariff_description", "");
    }
  }, [setValue, watchTariff]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          p: 4,
        }}
      >
        {loading && <LoadingIndicator />}
        <Grid alignItems="center" container direction="column" spacing={4}>
          <Grid item>
            <Typography variant="h4">
              {t("src.pages.AzureChangeTariff.master")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t("src.pages.AzureChangeTariff.toChangeTariff")}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Stack alignItems="center" spacing={2}>
                  <Typography>
                    {t("src.pages.AzureChangeTariff.currentTariff")}
                  </Typography>
                  <FormProvider {...methodsOldTariff}>
                    <form style={{ width: "100%" }}>
                      <CustomTextField
                        disabled
                        label={t("src.pages.AzureChangeTariff.currentTariff")}
                        name="Tariffs.TariffName"
                      />
                      <CustomTextField
                        disabled
                        label={t("src.pages.AzureChangeTariff.conditions")}
                        name="Tariffs.TariffDescription"
                      />
                      <CustomDatepicker
                        disabled
                        label={t("src.pages.AzureChangeTariff.nextPayment")}
                        name="CalculationDate"
                      />
                      <CustomDatepicker
                        disabled
                        label={t("src.pages.AzureChangeTariff.expirationDate")}
                        name="ActiveTo"
                      />
                    </form>
                  </FormProvider>
                </Stack>
              </Grid>
              <Grid item md={6} xs={12}>
                <Stack alignItems="center" spacing={2}>
                  <Typography>
                    {t("src.pages.AzureChangeTariff.newTariff")}
                  </Typography>
                  <FormProvider {...methodsNewTariff}>
                    <form
                      id="form-change-tariff-fixed"
                      onSubmit={handleSubmit(onSubmit)}
                      style={{ width: "100%" }}
                    >
                      <CustomAutocomplete
                        getOptionLabel={(option) => option.TariffName || ""}
                        isOptionEqualToValue={(option, value) =>
                          option.TariffID === value.TariffID
                        }
                        label={t("src.pages.AzureChangeTariff.tariffSelection")}
                        name="tariff"
                        options={tariffs}
                        required
                      />
                      <CustomTextField
                        disabled
                        label={t("src.pages.AzureChangeTariff.conditions")}
                        name="tariff_description"
                      />
                      <CustomDatepicker
                        disabled
                        label={t("src.pages.AzureChangeTariff.effectiveDate")}
                        name="date1"
                      />
                      <CustomDatepicker
                        disabled
                        label={t("src.pages.AzureChangeTariff.expirationDate")}
                        name="date2"
                      />
                    </form>
                  </FormProvider>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              {t("src.pages.AzureChangeTariff.tariffChanged")}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              form="form-change-tariff-fixed"
              type="submit"
              variant="contained"
            >
              {t("src.pages.AzureChangeTariff.changeTariff")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default AzureChangeTariffFixed;
