import React, { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PaymentIcon from "@mui/icons-material/Payment";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const MyAccountsAll = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationId, setConfirmationId] = useState(null);

  const { setMyAccounts, setSelectedMyAccount } = useActions();
  const { accounts } = useSelector((state) => state.accountsReducer);

  const tableColumns = [
    {
      accessorKey: "Accounts",
      header: t("src.pages.MyAccountsAll.name"),
      accessorFn: (row) => row.Accounts?.AccountName || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "InvoiceDate",
      header: t("src.pages.MyAccountsAll.date"),
      accessorFn: (row) => dayjs(row.InvoiceDate).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "TotalAmount",
      header: t("src.pages.MyAccountsAll.amount"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "state",
      header: t("src.pages.MyAccountsAll.state"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "PaymentMethods",
      header: t("src.pages.MyAccountsAll.payment"),
      accessorFn: (row) => row.PaymentMethods?.PaymentMethodName || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handlePay(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title={t("main.another.pay")}>
              <IconButton
                color="inherit"
                onClick={() => setConfirmationId(row.original.id)}
                size="large"
              >
                <PaymentIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handlePay = async (row) => {
    try {
      // await $authHost.delete(`/contacts/${row}/delete/`).then((response) => {
      //   loadData();
      // });
    } catch (e) {
      console.log(e);
    }
  };

  const handleView = (row) => {
    console.log(row);
    setSelectedRow(row.original);
    setSelectedMyAccount(row.original.InvoiceID);

    navigate(`/my-accounts/${row.original.InvoiceID}`);

    // openForm !== 'View' && setOpenForm('View');
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/invoice/get_all`);
      setMyAccounts(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setMyAccounts]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box pt={2}>
      <Card sx={{ pt: 1 }}>
        {/*{loading && <LoadingIndicator />}*/}
        <MaterialTable
          columns={tableColumns}
          data={accounts}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleView(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
        />
      </Card>
    </Box>
  );
};

export default MyAccountsAll;
