import React, { useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IconButton, Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import SkeletonInput from "./SkeletonInput";

const CustomViewField = ({
  label,
  text,
  loading,
  sx = {},
  type = "text",
  path = "",
  copyButton = false,
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textToCopy = renderText();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена: ", error);
      });
  };
  const renderText = () => {
    if (text && (typeof text === "string" || typeof text === "number")) {
      return text;
    }
    return t("src.components.CustomViewField.NotFound");
  };

  if (type === "link") {
    return (
      <>
        <Typography variant="caption">{label}</Typography>
        <SkeletonInput loading={loading}>
          <Link target="_blank" to={path}>
            <Typography
              sx={{
                mb: 2,
                ...sx,
              }}
            >
              {renderText()}
            </Typography>
          </Link>
        </SkeletonInput>
      </>
    );
  }

  if (type === "text") {
    return (
      <>
        <Typography variant="caption">{label}</Typography>
        <Stack
          alignItems="center"
          direction="row"
          sx={{
            pb: 2,
          }}
        >
          <SkeletonInput loading={loading}>
            <Typography
              sx={{
                pr: 1,
                ...sx,
              }}
            >
              {renderText()}
            </Typography>
          </SkeletonInput>
          {copyButton && !copied && (
            <Tooltip title="Копировать">
              <IconButton
                color="inherit"
                onClick={() => copyToClipboard()}
                size="small"
              >
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          )}
          {copyButton && copied && (
            <IconButton
              color="inherit"
              onClick={() => copyToClipboard()}
              size="small"
            >
              <DoneIcon />
            </IconButton>
          )}
        </Stack>
      </>
    );
  }

  return <></>;
};
export default CustomViewField;
