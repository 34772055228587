import React, { useEffect } from "react";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useActions } from "../../hook/useActions";
import {
  StyledContent,
  StyledRoot,
  StyledRootUpMD,
} from "../../theme/standarts_styles";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { isDarkTheme } = useSelector((state) => state.serviceReducer);

  const { setOpenNav } = useActions();

  useEffect(() => {
    setOpenNav(false);
  }, [setOpenNav]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        // backgroundImage: `url("/assets/illustrations/image_404.png")`
      }}
    >
      <StyledRoot>
        <Container maxWidth="lg">
          <StyledContent width={900}>
            <Card
              sx={{
                p: 4,
                backgroundColor: "layout.main",
              }}
            >
              <Grid
                alignItems="center"
                container
                direction="column"
                justifyContent="center"
                p={3}
              >
                <Grid item>
                  <Typography
                    align="center"
                    color="layout.element"
                    fontSize="200pt"
                    fontWeight="700"
                    lineHeight="1"
                  >
                    404
                  </Typography>
                  <Typography
                    align="center"
                    color="layout.element"
                    variant="h2"
                  >
                    {t("src.pages.NotFoundPage.pageNotFoundMessage")}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    endIcon={<HomeOutlinedIcon />}
                    href="/"
                    size="large"
                    sx={{
                      mt: 5,
                      borderRadius: "30px",
                    }}
                    variant="contained"
                  >
                    {t("src.pages.NotFoundPage.toGeneral")}
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </StyledContent>
        </Container>
      </StyledRoot>
    </div>
  );
};

export default NotFoundPage;
