import React, { useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import AlertMessage from "../../components/Alert/AlertMessage";
import CustomTextField from "../../components/Fields/CustomTextField";
import Footer from "../../components/Footer";
import { $customHost } from "../../http";

const AzureCreatePassword = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit, control } = methods;

  const watchPassword = useWatch({ control, name: "password" });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const onSubmit = async ({ confirmPassword, ...data }) => {
    setLoading(true);
    try {
      // await $customHost.post(`https://auth.aspex.cloud/auth/change_password`, {data});
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={4}
          sx={{ maxWidth: "600px" }}
        >
          <Grid item>
            <Typography variant="h4">
              {t("src.pages.AzureCreatePassword.emailConfirmed")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t("src.pages.AzureCreatePassword.thanksForConfirmed")}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextField
                  label={t("src.pages.AzureCreatePassword.surname")}
                  name="last_name"
                />
                <CustomTextField
                  label={t("src.pages.AzureCreatePassword.name")}
                  name="first_name"
                />
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t("src.pages.AzureCreatePassword.password")}
                  name="password"
                  required
                  type={showPassword ? "text" : "password"}
                />
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t("src.pages.AzureCreatePassword.passwordAgain")}
                  name="confirmPassword"
                  required
                  rules={{
                    validate: (value) => {
                      return (
                        value === watchPassword || t("main.another.wrongInput")
                      );
                    },
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                />
                <AlertMessage />
                <Button
                  sx={{ width: "100%" }}
                  type="submit"
                  variant="contained"
                >
                  {t("src.pages.AzureCreatePassword.send")}
                </Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default AzureCreatePassword;
