import { Outlet } from "react-router-dom";

import Header from "./header/Header";
import { Main, StyledRoot } from "../theme/standarts_styles";

const AzureLayout = () => {
  return (
    <StyledRoot>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
};

export default AzureLayout;
