import React, { forwardRef } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { Box, Grid, IconButton, Link } from "@mui/material";
import { useSelector } from "react-redux";

import { darkLogo, lightLogo, NAV_WIDTH, NAV_WIDTH_CLOSE } from "../constants";
import { useActions } from "../hook/useActions";
import useResponsive from "../hook/useResponsive";

// eslint-disable-next-line react/display-name
const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const { status } = useSelector((state) => state.authReducer);
  const isDesktop = useResponsive("up", "lg");
  const { isDarkTheme } = useSelector((state) => state.serviceReducer);
  const srcLogo = isDarkTheme ? lightLogo : darkLogo;
  const { openNav } = useSelector((state) => state.serviceReducer);
  const { toggleOpenNav } = useActions();

  const isMobile = useResponsive("down", "sm");

  return (
    <>
      {status === "Active" ? (
        <Box
          sx={{
            p: 0,
            width: NAV_WIDTH,
          }}
        >
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-start"
            sx={{
              p: 1,
              width: isMobile ? NAV_WIDTH_CLOSE : NAV_WIDTH,
            }}
          >
            {openNav ? (
              <>
                <IconButton m={0} onClick={() => toggleOpenNav()}>
                  <MenuIcon
                    size="small"
                    sx={{
                      color: "layout.element",
                    }}
                  />
                </IconButton>
                <Link href="/">
                  <Box
                    component="img"
                    src={srcLogo}
                    sx={{
                      height: 20,
                      ml: 1,
                      cursor: "pointer",
                      ...sx,
                    }}
                  />
                </Link>
              </>
            ) : (
              <>
                <Box
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{
                    width: NAV_WIDTH_CLOSE,
                    p: 1,
                  }}
                >
                  <IconButton m={0} onClick={() => toggleOpenNav()}>
                    <MenuIcon
                      size="small"
                      sx={{
                        color: "layout.element",
                      }}
                    />
                  </IconButton>
                </Box>
                {!isMobile && (
                  <Link href="/">
                    <Box
                      component="img"
                      src={srcLogo}
                      sx={{
                        height: 20,
                        ml: 1,
                        cursor: "pointer",
                        ...sx,
                      }}
                    />
                  </Link>
                )}
              </>
            )}
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            p: 1,
            width: NAV_WIDTH,
          }}
        >
          <Box
            component="img"
            src={srcLogo}
            sx={{
              height: 20,
              ml: 1,
              cursor: "pointer",
              ...sx,
            }}
          />
        </Box>
      )}
    </>
  );
});

export default Logo;
