import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";

import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import { TitleStackButton } from "../../theme/standarts_styles";

const SubscriptionManage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const { subscriptionId } = useParams();

  const tabs = [
    {
      label: t("src.pages.SubscriptionManage.general"),
      path: "general",
    },
    {
      label: t("src.pages.SubscriptionManage.transactions"),
      path: "transactions",
    },
    { label: t("src.pages.SubscriptionManage.charts"), path: "charts" },
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Container>
      <TitleStackButton>
        <Typography variant="h4">
          {t("src.pages.SubscriptionManage.subscriptionManagement")}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            href={`${window.location.origin}/azure/change-tariff-fixed/${subscriptionId}`}
            rel="noreferrer"
            target="_blank"
            variant="outlined"
          >
            {t("src.pages.SubscriptionManage.changeTariffFixed")}
          </Button>
          <Button
            href={`${window.location.origin}/azure/change-tariff-prorated/${subscriptionId}`}
            rel="noreferrer"
            target="_blank"
            variant="outlined"
          >
            {t("src.pages.SubscriptionManage.changeTariffProrated")}
          </Button>
          <Button variant="text">
            {t("src.pages.SubscriptionManage.unsubscribe")}
          </Button>
        </Stack>
      </TitleStackButton>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default SubscriptionManage;
