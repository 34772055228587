import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: "max-content",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: theme.palette.grey[400],
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[100],
          backgroundColor: theme.palette.grey[600],
          boxShadow: theme.customShadows.z4,
          "&:hover": {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.layout.element,
          boxShadow: theme.customShadows.layout_element,
          "&:hover": {
            backgroundColor: theme.palette.layout.element,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.layout.element}`,
          color: theme.palette.layout.element,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
            border: `1px solid ${alpha(theme.palette.grey[500], 0.9)}`,
          },
        },
        containedSecondary: {
          backgroundColor: theme.palette.layout.element,
          color: theme.palette.layout.main,
          // boxShadow: theme.customShadows.corp_element,
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        },
        outlinedSecondary: {
          border: `1px solid ${theme.palette.layout.element}`,
          color: theme.palette.layout.element,
          "&:hover": {
            backgroundColor: theme.palette.background.neutral,
            border: `1px solid ${theme.palette.layout.element}`,
          },
        },
        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.9)}`,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textPrimary: {
          color: theme.palette.layout.element,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
        containedAccent: {
          backgroundColor: theme.palette.layout.accent_element,
          color: theme.palette.layout.main,
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
