import React, { useCallback, useEffect, useState } from "react";

import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Card,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import TenantManagementAdd from "./TenantManagementAdd";
import TenantManagementView from "./TenantManagementView";
import Iconify from "../../components/iconify";
import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { ButtonStack, TitleStack } from "../../theme/standarts_styles";
// import BlockFormModal from "./BlockFormModal";

const TenantManagement = () => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState("");
  const [confirmationId, setConfirmationId] = useState(null);

  const { setTenants, setSelectedTenant } = useActions();

  const { tenants, selectedTenant } = useSelector(
    (state) => state.tenantsReducer,
  );

  const tableColumns = [
    {
      accessorKey: "TenantID",
      header: t("src.pages.TenantManagement.number"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "TenantName",
      header: t("src.pages.TenantManagement.name"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "role",
      header: t("src.pages.TenantManagement.role"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      muiTableBodyCellProps: {
        onClick: (e) => {},
        sx: {
          cursor: "auto",
        },
      },
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleChange(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }

        return (
          <>
            <Tooltip title={t("src.pages.TenantManagement.changeTenant")}>
              <IconButton
                color="inherit"
                onClick={() => setConfirmationId(row.original.id)}
                size="large"
              >
                <ChangeCircleIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleChange = async (row) => {
    try {
      // await $authHost.delete(`/contacts/${row}/delete/`).then((response) => {
      //   loadData();
      // });
    } catch (e) {
      console.log(e);
    }
  };

  const handleAdd = () => {
    setSelectedRow(null);
    setOpenForm((prevState) => {
      return prevState === "Add" ? "" : "Add";
    });
  };

  const handleView = (row) => {
    setSelectedRow(row.original);
    setSelectedTenant(row.id);

    openForm !== "View" && setOpenForm("View");
  };

  const handleClose = () => {
    setOpenForm("");
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/tenant/me`);

      setTenants(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setTenants]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!openForm && selectedTenant) {
      setSelectedTenant(null);
    }
  }, [openForm, selectedTenant, setSelectedTenant]);

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack>
        <Typography gutterBottom sx={{ mb: 0 }} variant="h4">
          {t("src.pages.TenantManagement.tenantManagement")}
        </Typography>
      </TitleStack>
      {/*{loading && <LoadingIndicator />}*/}
      <ButtonStack>
        <Button
          aria-haspopup="true"
          href={`${window.location.origin}/azure/create-tenant`}
          rel="noreferrer"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ mr: 0 }}
          target="_blank"
          variant="contained"
        >
          {t("src.pages.TenantManagement.addTenant")}
        </Button>
      </ButtonStack>
      {/*<AlertMessage/>*/}

      {openForm && (
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Card sx={{ p: 3, mb: 2 }}>
              {openForm === "Add" && (
                <TenantManagementAdd handleClose={handleClose} />
              )}
              {openForm === "View" && (
                <TenantManagementView
                  account={selectedRow}
                  handleClose={handleClose}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      )}
      <Card sx={{ pt: 1 }}>
        <MaterialTable
          columns={tableColumns}
          data={tenants}
          loading={loading}
          muiTableBodyCellProps={({ row }) => ({
            onClick: () => {
              handleView(row);
            },
            sx: {
              cursor: "pointer",
            },
          })}
        />
      </Card>
      {/*<BlockFormModal when={!!confirmationId} title='Вы не завершили действие внутри таблицы' />*/}
    </Container>
  );
};

export default TenantManagement;
