import { Button, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import Iconify from "../../components/iconify";
import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import { TitleStackButton } from "../../theme/standarts_styles";
// import BlockFormModal from "./BlockFormModal";

const MyAccounts = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { accounts } = useSelector((state) => state.accountsReducer);

  const tabs = [
    {
      label: t("src.pages.MyAccounts.accountsTab"),
      path: "all",
      count: accounts.length,
    },
    {
      label: t("src.pages.MyAccounts.unpaidTab"),
      path: "unpaid",
      count: accounts.length,
    },
    {
      label: t("src.pages.MyAccounts.paidTab"),
      path: "paid",
      count: accounts.length,
    },
    {
      label: t("src.pages.MyAccounts.canceledTab"),
      path: "canceled",
      count: accounts.length,
    },
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  const handleAdd = () => {
    navigate(`/my-accounts/new`);
  };

  return (
    <Container>
      <TitleStackButton>
        <Typography variant="h4">{t("src.pages.MyAccounts.title")}</Typography>
        <Button
          aria-haspopup="true"
          onClick={handleAdd}
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ mr: 0 }}
          variant="contained"
        >
          {t("src.pages.MyAccounts.invoice")}
        </Button>
      </TitleStackButton>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default MyAccounts;
