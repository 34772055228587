import React, { useState } from "react";

import { Box, Button, Card, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import bookImage from "./assets/images/2Black.png";
import logo from "./assets/images/aspex.png";
import bllogo from "./assets/images/blLogo.png";
import fileCaseImage from "./assets/images/file-case.png";
import ideaImage from "./assets/images/idea.png";
import Image from "./assets/images/img.jpg";
import notebookImage from "./assets/images/note_3.png";
import teamImage from "./assets/images/team.png";
import CustomTextField from "../../../components/Fields/CustomTextField";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { $authHost } from "../../../http";

import "../land_styles.css";

const LandingLeadmagnet = () => {
  document.title = "Как начать зарабатывать с ИИ?";
  document.description =
    "Как начать зарабатывать с ИИ? 4 кейса, которые изменят ваше представление об искусственном интеллекте";
  document.image = Image;

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);
    try {
      await axios.post(
        `https://my-bk-dev.aspex.cloud/mailchimp/pdf_mailing/subscribe`,
        data,
      );
      setSuccess(true);
    } catch (e) {
      setError(
        "Произошла ошибка. Пожалуйста, проверьте введенные данные и повторите попытку.",
      );
    } finally {
      setLoading(false);
    }
  };

  const features = [
    {
      image: teamImage,
      lead: "Экспертные мнения",
      text: "Взгляд руководителей онлайн-школ на применение ИИ: как ИИ меняет игру и тренды",
    },
    {
      image: fileCaseImage,
      lead: "4 уникальных кейса",
      text: "Реальные результаты от применения ИИ в графиках и цифрах",
    },
    {
      image: ideaImage,
      lead: "Практические рекомендации",
      text: "Как эффективно внедрить ИИ: пошаговые советы от золотого партнера Microsoft, специально для онлайн-школ",
    },
  ];

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        direction: "column",
        justifyContent: "center",
        backgroundColor: "#ffffff",
      }}
    >
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="center"
        spacing={0}
        sx={{
          width: "100wh",
          backgroundColor: "#111",
          pt: "30px",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
        }}
        width="100wh"
      >
        <Box component="img" src={logo} sx={{ height: 20 }} />
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="center"
        spacing={0}
        sx={{
          width: "100wh",
          height: {
            sm: "auto",
            xs: "70vh",
            lg: "auto",
            md: "auto",
            xl: "auto",
          },
          backgroundColor: "#111",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
          py: { xl: "50px", lg: "50px", md: "50px", sm: "20px", xs: "40px" },
        }}
        width="100wh"
      >
        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Typography
            className="header_text"
            style={{
              marginBottom: "0px",
            }}
            sx={{
              textAlign: {
                xl: "left",
                lg: "left",
                md: "center",
                sm: "center",
                xs: "center",
              },
              marginTop: { xl: "0", lg: "0", md: "0", sm: "5vh", xs: "5vh" },
            }}
          >
            Внедрили ИИ
          </Typography>
          <Typography
            sx={{
              textAlign: {
                xl: "left",
                lg: "left",
                md: "center",
                sm: "center",
                xs: "center",
              },
              marginBottom: {
                xl: "0",
                lg: "0",
                md: "10px",
                sm: "15px",
                xs: "15px",
              },
            }}
            variant="h1"
          >
            <span className="header_text" style={{ marginRight: "20px" }}>
              в
            </span>
            <Typography
              className="header_text"
              component="span"
              sx={{
                background: "#fff",
                // padding: '8px 15px 8px 15px',
                padding: {
                  xl: "8px 15px 8px 15px",
                  lg: "8px 15px 8px 15px",
                  md: "8px 15px 8px 15px",
                  sm: "5px 8px 5px 8px",
                  xs: "5px 8px 5px 8px",
                },
              }}
            >
              <span
                className="header_text"
                style={{
                  color: "#34495e",
                  fontWeight: 900,
                  textTransform: "uppercase",
                }}
              >
                get
              </span>
              <span
                className="header_text"
                style={{
                  color: "#2cbeb1",
                  fontWeight: 900,
                  textTransform: "uppercase",
                }}
              >
                course
              </span>
            </Typography>
          </Typography>
        </Grid>
        <Grid
          alignItems="center"
          display="flex"
          item
          justifyContent={{
            xl: "center",
            lg: "center",
            md: "center",
            sm: "center",
            xs: "center",
          }}
          lg={6}
          md={12}
          sm={12}
          xl={6}
          xs={12}
        >
          <img
            className="img_get"
            src={notebookImage}
            style={{ margin: "0px auto" }}
          />
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="center"
        spacing={0}
        sx={{
          width: "100%",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
          py: { xl: "50px", lg: "50px", md: "50px", sm: "20px", xs: "0px" },
        }}
      >
        <Grid
          alignItems="center"
          item
          justifyContent="center"
          lg={6}
          md={12}
          px={{ md: 0, xs: 0 }}
          py={{ md: 4, xs: 4 }}
          sm={12}
          sx={{
            display: "flex",
          }}
          xl={6}
          xs={12}
        >
          <img className="book_img" src={bookImage} />
          {/*  position: {md: 'absolute', xs: 'static'},*/}

          {/*  transform: {md: 'translateY(-50%)', xs: 'none'},*/}
        </Grid>
        <Grid
          alignItems="center"
          item
          justifyContent="flex-start"
          lg={6}
          md={12}
          sm={12}
          sx={{
            display: "flex",
          }}
          xl={6}
          xs={12}
        >
          <Box
            sx={{
              maxWidth: "1000px",
              // height: '100%',
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography className="text_lead" sx={{ fontWeight: 400 }}>
              Получите нашу книгу
              <span style={{ fontWeight: 700 }}> бесплатно и узнайте</span> как
              ведущие онлайн-школы уже сегодня используют ИИ для трансформации
              образования
            </Typography>
            <Typography
              align="left"
              className="text_about"
              mb={2}
              mt={7}
              sx={{ fontWeight: 500 }}
            >
              Внутри вы найдете:
            </Typography>
            <Stack py={2} spacing={5} sx={{ maxWidth: "700px" }}>
              {features.map((feature) => (
                <Box
                  key={feature.text}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <img alt="" src={feature.image} style={{ width: "60px" }} />
                  <div>
                    <Typography
                      className="dot_text"
                      sx={{
                        fontWeight: 500,
                        paddingLeft: "22px",
                        marginBottom: "10px",
                      }}
                    >
                      {feature.lead}
                    </Typography>
                    <Typography
                      className="dot_text"
                      sx={{ paddingLeft: "22px" }}
                    >
                      {feature.text}
                    </Typography>
                  </div>
                </Box>
              ))}
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justifyContent="center"
        spacing={0}
        sx={{
          width: "100%",
          pt: { xl: "60px", lg: "60px", md: "60px", sm: "50px", xs: "30px" },
          pb: { xl: "100px", lg: "100px", md: "100px", sm: "50px", xs: "30px" },
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
        }}
      >
        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Typography mb={2} variant="h2">
            Получите бесплатную копию книги
          </Typography>
          <Typography variant="h4">
            Просто оставьте ваш email, и мы вышлем вам книгу совершенно
            бесплатно!
          </Typography>
        </Grid>
        <Grid item lg={6} md={12} sm={12} xl={6} xs={12}>
          <Box
            // sx={{maxWidth: '700px', position: 'relative', }}
            pt={2}
          >
            {success ? (
              <Box
                sx={{ height: "252px", display: "flex", alignItems: "center" }}
              >
                <Typography variant="h5">
                  Ваша книга в пути! Пожалуйста, проверьте вашу почту - мы уже
                  отправили вам письмо с книгой "Как начать зарабатывать с ИИ? 4
                  кейса, которые изменят ваше представление об искусственном
                  интеллекте"
                </Typography>
              </Box>
            ) : (
              <>
                <Card
                  sx={{
                    padding: "30px",
                  }}
                >
                  <FormProvider {...methods}>
                    {loading && <LoadingIndicator />}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <CustomTextField
                        label="Ваше имя"
                        name="first_name"
                        required
                      />
                      <CustomTextField
                        label="Электронная почта"
                        name="email"
                        required
                        rules={{
                          required: t("main.another.requiredField"),
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: t("main.another.wrongInput"),
                          },
                        }}
                      />
                      <CustomTextField
                        label="Название вашей школы"
                        name="company_name"
                        required
                      />
                      <Button
                        color="button_red"
                        sx={{ width: "100%" }}
                        type="submit"
                        variant="contained"
                      >
                        Получить книгу бесплатно
                      </Button>
                      {error && (
                        <Typography color="error" pt={2} variant="body2">
                          {error}
                        </Typography>
                      )}
                    </form>
                  </FormProvider>
                </Card>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid
        alignItems="center"
        container
        direction="column"
        justifyContent="center"
        sx={{
          width: "100wh",
          backgroundColor: "#ececec",
          pt: "30px",
          px: { xl: "100px", lg: "100px", md: "50px", sm: "40px", xs: "20px" },
        }}
        width="100wh"
      >
        <Box
          component="img"
          src={bllogo}
          sx={{
            height: 20,
            mb: 4,
            mt: 3,
          }}
        />
        <Typography align="center" mb={1} variant="h6">
          Нужна помощь с ИИ? Свяжитесь с нами
        </Typography>
        <Typography mb={1} variant="h6">
          info@aspex.kz
        </Typography>
        <Typography align="center" mb={5} variant="body1">
          Copyright ASPEX Group 2016-2024. All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LandingLeadmagnet;
