import React, { useCallback, useEffect, useRef, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Popover,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AlertMessage from "../../components/Alert/AlertMessage";
import AddEditFormButtonGroup from "../../components/Buttons/AddEditFormButtonGroup";
import CustomTextField from "../../components/Fields/CustomTextField";
import BaseAddEditForm from "../../components/Forms/BaseAddEditForm";
import AccessUserFields from "../../components/Forms/FormFields/AccessUserFields";
import Iconify from "../../components/iconify";
import Label from "../../components/label/Label";
import MaterialTable from "../../components/MaterialTable";
import LoadingSpinner from "../../components/modals/loadingSpinner";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { ButtonStack } from "../../theme/standarts_styles";
import { fDateTime } from "../../utils/formatTime";

export default function AccessUserPage() {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [groups, setGroups] = useState(null);
  const [confirmationId, setConfirmationId] = useState(null);
  const [confirmationForEditForm, setConfirmationForEditForm] = useState(null);
  const fieldsRef = useRef(null);
  const fieldsHeight = fieldsRef?.current?.clientHeight || null;

  const tableColumns = [
    {
      accessorKey: "email",
      header: t("src.pages.UserManage.table_email"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "name",
      header: t("src.pages.UserManage.table_name"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{`${row.original.name} ${row.original.last_name}`}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "groups",
      header: t("src.pages.UserManage.table_group"),
      filterVariant: "select",
      filterSelectOptions: ["Менеджер", "Админ"],
      Cell: ({ renderedCellValue, row }) => {
        if (Array.isArray(renderedCellValue)) {
          return (
            <TableCellsWrap>
              <div>
                {renderedCellValue
                  .map((group) => group.name)
                  .sort()
                  .join(", ")}
              </div>
            </TableCellsWrap>
          );
        }

        return <TableCellsWrap>Не найдено</TableCellsWrap>;
      },
    },
    {
      accessorKey: "created_at",
      header: t("src.pages.UserManage.table_created_at"),
      accessorFn: (row) => new Date(row.created_at),
      filterFn: "lessThanOrEqualTo",
      sortingFn: "datetime",
      Cell: ({ renderedCellValue, row }) => (
        // <TableCellsWrap>{fDateTime(renderedCellValue)}</TableCellsWrap>
        <TableCellsWrap></TableCellsWrap>
      ),
      Filter: ({ column }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            onChange={(newValue) => {
              column.setFilterValue(newValue);
            }}
            slotProps={{
              textField: {
                helperText: "Filter Mode: Less Than",
                sx: { minWidth: "120px" },
                variant: "standard",
              },
            }}
            value={column.getFilterValue()}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: "status",
      header: t("src.pages.UserManage.table_status"),
      Cell: ({ renderedCellValue, row }) => (
        <Label
          color={row.original.status === "Active" ? "secondary" : "primary"}
        >
          {renderedCellValue}
        </Label>
      ),
    },
    {
      accessorKey: "actions",
      size: 150,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        if (confirmationId === row.original.id) {
          return (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={() => handleDeleteRow(row.original.id)}
                  size="large"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationId(null)}
                  size="large"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }
        return (
          <>
            <IconButton
              color="primary"
              onClick={() => handleGoToEditPage(row.original)}
              size="large"
            >
              <EditIcon />
            </IconButton>
            <Switch
              checked={row.original.status !== "Disabled"}
              onChange={(event, checked) => handleIsRun(row.original, !checked)}
              size="small"
            />
            <IconButton
              color="primary"
              onClick={() => setConfirmationId(row.original.id)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  const {
    setInfoAlertMessage,
    resetAllAlertMessage,
    setSuccessAlertMessage,
    setErrorAlertMessage,
    setUsers,
  } = useActions();

  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, reset } = methods;

  const { users } = useSelector((state) => state.usersReducer);

  async function handleDeleteRow(id_row) {
    setLoading(true);
    try {
      // await $authHost.delete(`/user/${id_row}/delete`)
      await $authHost.delete(`/user/${id_row}/delete`);
      loadData();
      if (id_row === selectedRow.id) {
        setOpenForm("");
      }
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.detail === "Cannot delete the only admin"
      ) {
        setConfirmationId(null);
        setConfirmationForEditForm(null);
        setErrorAlertMessage(
          t(
            "src.pages.UserManage.removeAdmin",
            'Вы не можете удалить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.',
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  }

  const getDropdownData = useCallback(async () => {
    try {
      const response = await $authHost.get(`/group/get_all`);
      setGroups(response.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onSubmitAddUser = async (data) => {
    const { email } = data;
    setLoading(true);
    try {
      await $authHost.post(`/user/invite_access_management`, data);
      resetAllAlertMessage();
      setSuccessAlertMessage(t("src.pages.UserManage.userInvited", { email }));
      loadData();
    } catch (e) {
      if (e.response.data.detail === "This is user already exist") {
        setInfoAlertMessage(t("src.pages.UserManage.alreadyExist", { email }));
      }
    } finally {
      handleAddUserClose();
      setLoading(false);
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    if (openForm === "Update") {
      setLoading(true);
      const { updated_at, created_at, ...update_data } = data;
      if (!update_data.id && !!update_data._id) {
        update_data.id = update_data._id; //Костыль для монго дб
      }
      try {
        await $authHost.patch(`/user/${data.id}/update`, update_data);
        loadData();
        setOpenForm("");
      } catch (e) {
        if (
          e.response.status === 400 &&
          e.response.data.detail === "Cannot disable the only admin"
        ) {
          setConfirmationId(null);
          setErrorAlertMessage(
            t(
              "src.pages.UserManage.disableAdmin",
              'Вы не можете отключить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.',
            ),
          );
        }
        if (
          e.response.status === 400 &&
          e.response.data.detail === "Cannot delete the only admin"
        ) {
          setConfirmationId(null);
          setErrorAlertMessage(
            t(
              "src.pages.UserManage.removeAdmin",
              'Вы не можете удалить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.',
            ),
          );
        }
        if (
          e.response.status === 400 &&
          e.response.data.detail === "Cannot remove User role"
        ) {
          setConfirmationId(null);
          setErrorAlertMessage(
            t(
              "src.pages.UserManage.disableUser",
              'Вы не можете отключить группу "Пользователь"!',
            ),
          );
        }
      } finally {
        setLoading(false);
      }
    } else if (openForm === "Add") {
      // const { id, updated_at, created_at, users, ...add_data } = data
      // try {
      // 	await $authHost.post(`/user/create`, add_data)
      // 	loadData()
      // 	setOpenForm('')
      // } catch (e) {
      // } finally {
      // }
    }
  };

  const loadData = useCallback(() => {
    try {
      const newChannelList = async () => {
        const response = await $authHost.get("/user/get_all");
        return response.data;
      };
      newChannelList().then((data) => {
        setUsers(data);
      });
    } catch (e) {
      console.log(e);
    }
  }, [setUsers]);

  function handleGoToEditPage(row) {
    setSelectedRow(row);
    setOpenForm("Update");
  }

  async function handleIsRun(row, is_run) {
    //заблокировать пользователя
    setLoading(true);
    try {
      const { id, ...update_data } = row;
      update_data.status = is_run
        ? "Disabled"
        : update_data.locked_flags === null
          ? "Active"
          : "Locked";
      await $authHost.patch(`/user/${id}/update`, update_data);
      loadData();
    } catch (e) {
      if (
        e.response.status === 400 &&
        e.response.data.detail === "Cannot disable the only admin"
      ) {
        setConfirmationId(null);
        setErrorAlertMessage(
          t(
            "src.pages.UserManage.disablelastAdmin",
            'Вы не можете отключить последнего активного пользователя с группой "Администратор"! Сначала назначьте нового.',
          ),
        );
      }
    } finally {
      setLoading(false);
    }
  }

  const handleAddUser = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAddUserClose = () => {
    reset({ email: "" });
    // setValue('email', '')
    setAnchorEl(null);
  };
  const openAddUser = Boolean(anchorEl);

  useEffect(() => {
    loadData();
  }, [loadData]);
  useEffect(() => {
    getDropdownData();
  }, [getDropdownData]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <>
        <ButtonStack>
          <Button
            aria-haspopup="true"
            onClick={handleAddUser}
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{ mr: 3 }}
            variant="contained"
          >
            {t("src.pages.UserManage.inviteUser")}
          </Button>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            id={"addUser"}
            onClose={handleAddUserClose}
            open={openAddUser}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Paper sx={{ p: 2 }}>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmitAddUser)}>
                  <Stack
                    alignItems="center"
                    direction="column"
                    justifyContent="space-between"
                    mb={0}
                    spacing={0}
                  >
                    <CustomTextField
                      label={t("src.pages.UserManage.emailLabel")}
                      name="email"
                      required
                      rules={{
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: t("main.another.wrongInput"),
                        },
                      }}
                    />
                    <Button color="primary" type="submit" variant="contained">
                      {t("src.pages.UserManage.sendInvitation")}
                    </Button>
                  </Stack>
                </form>
              </FormProvider>
            </Paper>
          </Popover>
        </ButtonStack>
        <AlertMessage />
        {openForm && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
              <Card sx={{ p: 3, mb: 2 }}>
                <BaseAddEditForm
                  action={openForm}
                  fieldsRef={fieldsRef}
                  onSubmit={onSubmit}
                  selectedRow={selectedRow}
                >
                  <AccessUserFields autocompleteOptions={groups} />
                  <AddEditFormButtonGroup
                    action={openForm}
                    confirmationForEditForm={confirmationForEditForm}
                    handleClose={() => setOpenForm("")}
                    handleDeleteRow={() => handleDeleteRow(selectedRow.id)}
                    setConfirmationForEditForm={setConfirmationForEditForm}
                  />
                </BaseAddEditForm>
              </Card>
            </Grid>
          </Grid>
        )}

        <Card sx={{ pt: 1 }}>
          <MaterialTable columns={tableColumns} data={users} />
        </Card>
      </>
    </>
  );
}
