import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";

import { rootWatcher } from "./saga";
import { accountsReducer } from "./slice/accounts.slice";
import { authReducer } from "./slice/auth.slice";
import { channelsReducer } from "./slice/channels.slice";
import { companyReducer } from "./slice/company.slice";
import { documentsReducer } from "./slice/documents.slice";
import { draftReducer } from "./slice/draft.slice";
import { fileReducer } from "./slice/file.slice";
import { productsReducer } from "./slice/products.slice";
import { serviceReducer } from "./slice/service.slice";
import { ssoReducer } from "./slice/sso.slice";
import { subscriptionsReducer } from "./slice/subscriptions.slice";
import { tableStatesReducer } from "./slice/tableStates.slice";
import { tariffsReducer } from "./slice/tariffs.slice";
import { tenantsReducer } from "./slice/tenants.slice";
import { usersReducer } from "./slice/users.slice";
import { uuidReducer } from "./slice/uuid.slice";
//import sseApi from "./api/api";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  authReducer,
  fileReducer,
  draftReducer,
  channelsReducer,
  usersReducer,
  serviceReducer,
  uuidReducer,
  ssoReducer,
  subscriptionsReducer,
  accountsReducer,
  tenantsReducer,
  tableStatesReducer,
  companyReducer,
  documentsReducer,
  productsReducer,
  tariffsReducer,
  // [sseApi.reducerPath]: sseApi.reducer,
});

const persistConfig = {
  key: "root",
  storage, // ~ storage: storage
  // blacklist: [''],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  // devTools: true, // new
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      sagaMiddleware, // добавляем sagaMiddleware в качестве middleware в магазин Redux
      // sseApi.middleware // Add the RTK-Query middleware to the middleware chain
    ),
});

sagaMiddleware.run(rootWatcher);

export const persistor = persistStore(store);
