import React from "react";

import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import RemoteDynamicTabs from "../../components/Tabs/RemoteDynamicTabs";
import { TitleStack } from "../../theme/standarts_styles";

const Documents = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const tabs = [
    {
      label: t("src.pages.Documents.closingDocuments"),
      path: "closing-documents",
    },
    {
      label: t("src.pages.Documents.personalDocuments"),
      path: "personal-documents",
    },
    { label: t("src.pages.Documents.contracts"), path: "contracts" },
  ];

  // Проверяем, если текущий путь не соответствует ни одному из путей в tabs, то выполняем редирект на первую вкладку
  if (!tabs.some((tab) => location.pathname.endsWith(tab.path))) {
    let redirectPath = location.pathname;
    if (!location.pathname.endsWith("/")) {
      redirectPath += "/";
    }
    redirectPath += tabs[0].path;
    return <Navigate to={redirectPath} />;
  }

  return (
    <Container>
      <TitleStack>
        <Typography variant="h4">
          {t("src.pages.Documents.documents")}
        </Typography>
      </TitleStack>
      <RemoteDynamicTabs tabs={tabs} />
      <Outlet /> {/* Отображение содержимого дочерних маршрутов */}
    </Container>
  );
};

export default Documents;
