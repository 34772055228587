import React, { Suspense } from "react";

import { HelmetProvider } from "react-helmet-async";

import LoadingSpinner from "./components/modals/loadingSpinner";
import { AuthProvider } from "./hoc/authProvider";
import { LoadProvider } from "./hoc/loadProvider";
import Router from "./routes";
import ThemeProvider from "./theme";

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Suspense fallback={<LoadingSpinner />}>
          <AuthProvider>
            <LoadProvider>
              <Router />
            </LoadProvider>
          </AuthProvider>
        </Suspense>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
