import { useEffect, useState } from "react";

import Box from "@mui/material/Box";

import { $host } from "../../http";

const LoginSuccess = () => {
  const [status, setStatus] = useState("progress");

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const params = {};
    hash.split("&").map((hk) => {
      let temp = hk.split("=");
      params[temp[0]] = temp[1];
    });

    let accessToken = params.access_token;
    let idToken = params.id_token;

    if (accessToken && idToken) {
      getToken(accessToken, idToken);
    }
  }, []);

  const getToken = async (accessToken, idToken) => {
    try {
      const response = await $host.get(
        `/sso/callback?access_token=${accessToken}&id_token=${idToken}`,
        {
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        },
      );

      const {
        data: { token },
        headers: { date },
      } = response;

      const utcResponseTime = new Date(date);
      const startTime = utcResponseTime.getTime(); //localTimeMilliseconds

      const channel = new BroadcastChannel("login-data");
      channel.postMessage({
        access_token: token?.access_token,
        startTime: startTime,
      });

      setStatus("success");

      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (e) {
      console.log(e);
      setStatus("error");
    }
  };

  if (status === "success") {
    return (
      <Box p={2}>
        Авторизация прошла успешно. Вы будете перенаправлены на главную
        страницу.
      </Box>
    );
  }

  if (status === "error") {
    return <Box p={2}>Произошла ошибка. Пожалуйста, попробуйте еще раз.</Box>;
  }

  return <Box p={2}>Идет авторизация. Пожалуйста, подождите...</Box>;
};

export default LoginSuccess;
