import React from "react";

import { useTranslation } from "react-i18next";

import CustomMultiTextFieldAutocompleteForObject from "../../Fields/CustomMultiTextFieldAutocompleteForObject";
import CustomTextField from "../../Fields/CustomTextField";

const AccessGroupsFields = ({
  autocompleteOptions,
  selectedRow,
  baseGroups,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomTextField
        disabled={baseGroups?.includes(selectedRow?.name) || false}
        label={t("src.pages.AccessGroupsPage.groupName")}
        name="name"
        required
      />
      <CustomMultiTextFieldAutocompleteForObject
        disabled={baseGroups?.includes(selectedRow?.name) || false}
        label={t("src.pages.AccessGroupsPage.roles")}
        multiple={true}
        name="roles"
        options={autocompleteOptions}
        rules={{
          required: t("main.another.requiredField"),
        }}
        size="small"
        sx={{ width: "100%", fontSize: "12px", mb: 3 }}
      />
      <CustomTextField
        label={t("src.pages.AccessGroupsPage.ssoGroup")}
        name="group_sso"
        required
      />
    </>
  );
};

export default AccessGroupsFields;
