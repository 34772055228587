import React, { useCallback, useEffect, useState } from "react";

import PaymentIcon from "@mui/icons-material/Payment";
import { Button, Card, Grid, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomDatepicker from "../../components/Fields/CustomDatepicker";
import CustomTextField from "../../components/Fields/CustomTextField";
import LoadingIndicator from "../../components/LoadingIndicator";
import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const SubscriptionGeneral = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { subscription } = useSelector((state) => state.subscriptionsReducer);
  const { tariffs } = useSelector((state) => state.tariffsReducer);
  const { setSubscription, setTariffs } = useActions();

  const { subscriptionId } = useParams();

  const tableColumns = [
    {
      accessorKey: "TariffName",
      header: t("src.pages.SubscriptionGeneral.serviceName"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "count",
      header: t("src.pages.SubscriptionGeneral.count"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <Tooltip title={t("main.another.buy")}>
            <IconButton
              color="inherit"
              onClick={() => handleBuy(row.original.id)}
              size="large"
            >
              <PaymentIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const handleBuy = (id) => {
    // setSelectedSubscription(id);
  };

  const getSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(
        `/subscription/get_one/${subscriptionId}`,
      );
      setSubscription(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setSubscription, subscriptionId]);

  const getTariffs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/tariff/get_all`);
      setTariffs(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setTariffs]);

  useEffect(() => {
    getSubscription();
    getTariffs();
  }, [getSubscription, getTariffs]);

  const methods = useForm({
    mode: "onBlur",
    defaultValues: subscription,
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    console.log(data);

    setLoading(true);
    try {
      // await $authHost.patch(`/update/${id}`, data);
    } catch (e) {
      setError("root.serverError", {
        type: "server",
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      navigate("/my-subscriptions");
    }
  }, [isSubmitSuccessful, navigate]);

  const handleBack = () => {
    navigate("/my-subscriptions");
  };

  const handleReset = () => {
    reset();
  };

  return (
    <Grid container direction="row" spacing={2} sx={{ my: 2 }}>
      {loading && <LoadingIndicator />}
      <Grid item md={6} xs={6}>
        <Card sx={{ px: 2, py: 4 }}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CustomTextField
                label={t("src.pages.SubscriptionGeneral.subscriptionName")}
                name="name"
                required
              />
              <CustomTextField
                disabled
                label={t("src.pages.SubscriptionGeneral.url")}
                name="Tenants.TenantDomainPrefix"
              />
              <CustomAutocomplete
                getOptionLabel={(option) => option.TariffName || ""}
                isOptionEqualToValue={(option, value) =>
                  option.TariffID === value.TariffID
                }
                label={t("src.pages.SubscriptionGeneral.tariff")}
                name="Tariffs"
                options={tariffs}
                required
              />
              <CustomTextField
                label={t("src.pages.SubscriptionGeneral.state")}
                name="SubscriptionStatus.Status"
                required
              />
              <CustomTextField
                label={t("src.pages.SubscriptionGeneral.balance")}
                name="Balance"
                required
              />
              <CustomDatepicker
                label={t("src.pages.SubscriptionGeneral.startDate")}
                name="ActiveFrom"
                required
              />
              <CustomDatepicker
                label={t("src.pages.SubscriptionGeneral.settlementDay")}
                name="CalculationDate"
                required
              />
              <CustomDatepicker
                label={t("src.pages.SubscriptionGeneral.completionDate")}
                name="ActiveTo"
                required
              />
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={2}>
                  <Button type="submit" variant="contained">
                    {t("main.another.save")}
                  </Button>
                  <Button onClick={handleBack} variant="text">
                    {t("main.another.back")}
                  </Button>
                </Stack>
                <Button onClick={handleReset} variant="text">
                  {t("main.another.reset")}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Card>
      </Grid>
      <Grid item md={6} xs={6}>
        <Card sx={{ px: 2, py: 4, height: "100%" }}>
          <MaterialTable
            columns={tableColumns}
            data={
              Array.isArray(subscription?.TariffServices)
                ? subscription?.TariffServices
                : []
            }
            positionExpandColumn="last"
            renderDetailPanel={({ row, table }) => (
              <Typography px={2} variant="body2">
                {row.original.TariffDescription}
              </Typography>
            )}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default SubscriptionGeneral;
