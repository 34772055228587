import React from "react";

// @mui
import { Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import {
  StyledContent,
  StyledRoot,
  StyledRootUpMD,
} from "../../theme/standarts_styles";
import CompletionRegistrationForm from "../forms/CompletionRegistrationForm";

const CompletionRegistration = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography align="center" gutterBottom variant="h4">
              {t(
                "src.pages.CompletionRegistration.completionRegistrationPageText",
              )}
            </Typography>
            <CompletionRegistrationForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};

export default CompletionRegistration;
