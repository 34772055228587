import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    delAllFile() {
      return initialState;
    },
    addDescriptionFile: (state, action) => {
      const { id, path, value } = action.payload;
      state[path] =
        id === "docType" ? { [id]: value } : { ...state[path], [id]: value };
    },
    delFile: (state, action) => {
      delete state[action.payload];
    },
  },
});

export const { actions: fileActions, reducer: fileReducer } = fileSlice;
export default fileSlice.reducer;
