import React, { useCallback, useEffect, useState } from "react";

import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AlertMessage from "../../components/Alert/AlertMessage";
import CustomAutocomplete from "../../components/Fields/CustomAutocomplete";
import CustomTextField from "../../components/Fields/CustomTextField";
import Footer from "../../components/Footer";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useActions } from "../../hook/useActions";
import { $authHost, $customHost } from "../../http";
import { webhooksUrl } from "../../http/baseRoute";

const AzureCreateSubscription = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [tariffs, setTariffs] = useState([]);

  const { setProducts } = useActions();
  const { products } = useSelector((state) => state.productsReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit, control } = methods;

  const onSubmit = async ({ product, tariff, DomainPrefix }) => {
    setLoading(true);
    const data = {
      TariffID: tariff?.TariffID,
      DomainPrefix: `${DomainPrefix}.ai.aspex.cloud`,
    };
    try {
      // await $customHost.post(`${webhooksUrl}/subscriptions/create`, data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/product/get_all`);
      setProducts(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setProducts]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const watchProduct = useWatch({ control, name: "product" });

  useEffect(() => {
    if (Array.isArray(watchProduct?.Tariffs)) {
      setTariffs(watchProduct?.Tariffs);
    } else {
      setTariffs([]);
    }
  }, [watchProduct]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          p: 4,
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={4}
          sx={{ maxWidth: "600px" }}
        >
          <Grid item>
            <Typography variant="h4">
              {t("src.pages.AzureCreateSubscription.wizard")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              {t("src.pages.AzureCreateSubscription.select")}
            </Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {loading && <LoadingIndicator />}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomAutocomplete
                  getOptionLabel={(option) => option.ProductName || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.ProductID === value.ProductID
                  }
                  label={t(
                    "src.pages.AzureCreateSubscription.selectionProduct",
                  )}
                  name="product"
                  options={products}
                  required
                />
                <CustomAutocomplete
                  getOptionLabel={(option) => option.TariffName || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.TariffID === value.TariffID
                  }
                  label={t("src.pages.AzureCreateSubscription.tariff")}
                  name="tariff"
                  options={tariffs}
                  required
                />
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        .ai.aspex.cloud
                      </InputAdornment>
                    ),
                  }}
                  label={t("src.pages.AzureCreateSubscription.instanceName")}
                  name="DomainPrefix"
                  registerOptions={{
                    pattern: {
                      value: /^[a-z0-9]{5,12}$/,
                      message: t("main.another.wrongInput"),
                    },
                  }}
                  required
                />
                <AlertMessage />
                <Button
                  disabled
                  sx={{ width: "100%" }}
                  type="submit"
                  variant="contained"
                >
                  {t("src.pages.AzureCreateSubscription.create")}
                </Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default AzureCreateSubscription;
