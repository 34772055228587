import React from "react";

import { Container, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { LoginForm } from "../../components/Forms/LoginForm";
import { StyledContent, StyledRoot } from "../../theme/standarts_styles";

export default function Login() {
  const { t } = useTranslation();
  return (
    <div className="ddddddd" style={{ height: "100%", background: "#F6F9FC" }}>
      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography
              align="center"
              color="layout.element"
              gutterBottom
              mt={6}
              variant="h3"
            >
              {t("src.pages.Login.authorization")}
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </div>
  );
}
