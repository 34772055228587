import { Button, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import CustomViewField from "../../components/CustomViewField";

const TenantManagementView = ({ handleClose, account }) => {
  const { t } = useTranslation();

  return (
    <>
      <CustomViewField
        label={t("src.pages.TenantManagementView.number")}
        text={account?.TenantID}
      />
      <CustomViewField
        label={t("src.pages.TenantManagementView.name")}
        text={account?.TenantName}
      />
      <CustomViewField
        label={t("src.pages.TenantManagementView.role")}
        text={account?.role}
      />
      <Grid item sx={{ width: "100%", mb: 2 }}>
        <Stack alignItems="center" direction="row" spacing={1}>
          {/*<Button*/}
          {/*  variant='contained'*/}
          {/*  onClick={() => setOpenForm('Update')}*/}
          {/*>*/}
          {/*  Редактировать*/}
          {/*</Button>*/}
          <Button color="primary" onClick={handleClose} variant="contained">
            {t("main.another.close")}
          </Button>
        </Stack>
      </Grid>
    </>
  );
};

export default TenantManagementView;
