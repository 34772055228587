import React from "react";

import { Container, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { StyledContent, StyledRootUpMD } from "../../theme/standarts_styles";
import ChangePasswordForm from "../forms/ChangePasswordForm";

const ChangePassword = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledRootUpMD>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography gutterBottom variant="h4">
              {t(
                "src.pages.ChangePassword.changePasswordPageText",
                "Смена пароля",
              )}
            </Typography>
            <ChangePasswordForm />
          </StyledContent>
        </Container>
      </StyledRootUpMD>
    </>
  );
};

export default ChangePassword;
