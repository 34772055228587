import React, { useEffect, useState } from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const Contracts = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { setDocuments } = useActions();

  const { documents } = useSelector((state) => state.documentsReducer);

  const tableColumns = [
    {
      accessorKey: "number",
      header: t("src.pages.ClosingDocuments.actNumber"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "date",
      header: t("src.pages.ClosingDocuments.date"),
      accessorFn: (row) => dayjs(row.date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "amount",
      header: t("src.pages.ClosingDocuments.amount"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 120,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <>
            <Tooltip title={t("main.another.download")}>
              <IconButton color="inherit" onClick={() => {}} size="large">
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/invoice/get_all`);
      setDocuments(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // loadData();
  }, []);

  return (
    <Box pt={2}>
      <Card sx={{ pt: 1 }}>
        <MaterialTable
          columns={tableColumns}
          data={[{ number: 1 }]}
          loading={loading}
        />
      </Card>
    </Box>
  );
};

export default Contracts;
