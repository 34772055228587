import React, { useCallback, useEffect, useState } from "react";

import { Button, Card, Divider, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import CustomViewField from "../../components/CustomViewField";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { TitleStackButton } from "../../theme/standarts_styles";

const MyAccountsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { setMyAccount } = useActions();
  const { account } = useSelector((state) => state.accountsReducer);

  const { accountId } = useParams();

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get(`/invoice/get_one/${accountId}`);
      setMyAccount(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [accountId, setMyAccount]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleBack = () => {
    navigate("/my-accounts");
  };

  return (
    <Container>
      {loading && <LoadingIndicator />}
      <TitleStackButton>
        <Typography variant="h4">
          {t("src.pages.MyAccountsView.title")} №{account?.InvoiceID}
        </Typography>
        <Button onClick={() => {}} variant="outlined">
          {t("src.pages.MyAccountsView.download")}
        </Button>
      </TitleStackButton>
      <Typography>
        {t("src.pages.MyAccountsView.formed")}{" "}
        {dayjs(account?.InvoiceDate).format("DD.MM.YYYY")}
      </Typography>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ pt: 2, pb: 1 }}
      >
        <Typography variant="h6">
          {t("src.pages.MyAccountsView.purpose")}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => {}} variant="contained">
            {t("main.another.pay")}
          </Button>
          <Button onClick={() => {}} variant="text">
            {t("main.another.cancel")}
          </Button>
        </Stack>
      </Stack>
      <Card sx={{ mb: 2, p: 3 }}>
        <Typography>
          {t("src.pages.MyAccountsView.paymentStatus")} -{" "}
          {dayjs(account?.InvoiceDate).format("DD.MM.YYYY")}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ pt: 1 }}
        >
          <Typography sx={{ fontSize: 18 }}>
            {t("src.pages.MyAccountsView.serviceName")}
          </Typography>
          <Typography>{account?.TotalAmount}</Typography>
        </Stack>
      </Card>
      <Typography sx={{ pb: 2 }} variant="h6">
        {t("src.pages.MyAccountsView.paymentStatus")}
      </Typography>
      <Card sx={{ mb: 2, p: 3 }}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{ pb: 1 }}
        >
          <Typography sx={{ fontSize: 18 }}>
            {t("src.pages.MyAccountsView.total")}
          </Typography>
          <Typography>{account?.TotalAmount}</Typography>
        </Stack>
        <Divider />
        <Stack sx={{ pt: 2 }}>
          <CustomViewField
            label={t("src.pages.MyAccountsView.transactionID")}
            text={account?.InvoiceID}
          />
          <CustomViewField
            label={t("src.pages.MyAccountsView.transactionDate")}
            text={dayjs(account?.InvoiceDate).format("DD.MM.YYYY")}
          />
          <CustomViewField
            label={t("src.pages.MyAccountsView.paid")}
            text={account?.PaymentMethodName}
          />
        </Stack>
      </Card>
      <Stack direction="row" spacing={2} sx={{ pb: 2 }}>
        {/*<Button variant='contained' onClick={handleEdit}>*/}
        {/*  Редактировать*/}
        {/*</Button>*/}
        <Button onClick={handleBack} variant="contained">
          {t("main.another.back")}
        </Button>
      </Stack>
    </Container>
  );
};

export default MyAccountsView;
