import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drafts: [],
  draftTasks: [],
  uploadTasks: [],
  isHideDropzone: null,
  currentUploadTable: false,
  fileTypes: [],
  lists: [],
  selectedWorkshop: null,
  workshopNums: [],
};

const draftSlice = createSlice({
  name: "draft",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setFileTypes(state, { payload }) {
      state.fileTypes = payload;
    },
    setLists(state, { payload }) {
      state.lists = payload;
    },
    setWorkshopNums(state, { payload }) {
      state.workshopNums = payload;
    },
    setCurrentUploadTable(state, { payload }) {
      state.currentUploadTable = payload;
    },
    setAcceptedList(state, { payload }) {
      const { id_draft, lists } = payload;
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.id_draft === id_draft,
      );
      state.drafts[FindIndex].data.acceptedList = lists;
    },
    updateDraftForm(state, { payload }) {
      const { id_draft, data } = payload;
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.id_draft === id_draft,
      );
      state.drafts[FindIndex].params = data;
    },
    setIsHideDropzone(state, { payload }) {
      state.isHideDropzone = payload;
    },
    setSelectedWorkshop(state, { payload }) {
      state.selectedWorkshop = payload;
    },
    setDrafts(state, { payload }) {
      state.drafts = [];
      payload.map((payload, index) => {
        state.drafts.push({
          id_draft: payload._id,
          isUploaded: true,
          task_id: payload.task_id,
          status: payload.status,
          file: {
            doc: null,
            name: payload.filename,
            path: null,
          },
          params: {
            file_type: payload.file_type,
            lists: payload.lists,
            workshop_num: payload.workshop_num,
            is_update: payload.is_update,
          },
          data: {
            acceptedList: [],
          },
        });
      });
    },
    addDraft(state, { payload }) {
      // const {id_draft, file, task } = payload;
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.file.name === payload.file.name,
      );
      if (FindIndex === -1 && state.drafts.length <= 2) {
        state.drafts.push(payload);
      }
    },
    deleteDraft(state, { payload }) {
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.file.name === payload,
      );
      state.drafts = state.drafts
        .slice(0, FindIndex)
        .concat(state.drafts.slice(FindIndex + 1));
    },
    updateUploadStatus(state, { payload }) {
      const { name, status } = payload;
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.file.name === name,
      );
      state.drafts[FindIndex].status = status;
    },
    UpdateUploadDraftId(state, { payload }) {
      const { name, task_id } = payload;
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.file.name === name,
      );
      state.drafts[FindIndex].task_id = task_id;
    },
    UpdateUploadDraft(state, { payload }) {
      const { task_id, id_draft, isUploaded } = payload;
      const FindIndex = state.drafts.findIndex(
        (draft) => draft.task_id === task_id,
      );
      state.drafts[FindIndex].id_draft = id_draft;
      state.drafts[FindIndex].isUploaded = isUploaded;
      state.drafts[FindIndex].status = "success";
    },
    addTaskDraft(state, { payload }) {
      state.draftTasks.push(payload);
    },
    killTaskDraft(state, { payload }) {
      state.draftTasks = state.draftTasks.filter(
        (task) => task.task_id !== payload,
      );
    },
    updateStatusTaskDraft(state, { payload }) {
      const { task_id, progress, status, id_draft } = payload;
      state.draftTasks = state.draftTasks.map((task) =>
        task.task_id === task_id
          ? {
              ...task,
              progress,
              status,
              id_draft,
            }
          : task,
      );
    },
    setTaskDraft(state, { payload }) {
      state.draftTasks = payload;
    },
    setUploadTask(state, { payload }) {
      state.uploadTasks = payload;
    },
    addUploadTask(state, { payload }) {
      state.uploadTasks.push(payload);
    },
    killUploadTask(state, { payload }) {
      state.uploadTasks = state.uploadTasks.filter(
        (task) => task.task_id !== payload,
      );
    },
    updateStatusUploadTask(state, { payload }) {
      const { task_id, progress, status, id_draft, reason, name } = payload;
      state.uploadTasks = state.uploadTasks.map((task) =>
        task.task_id === task_id
          ? {
              ...task,
              progress,
              status,
              id_draft,
              reason,
              name,
            }
          : task,
      );
    },
  },
});

export const { actions: draftActions, reducer: draftReducer } = draftSlice;
export default draftSlice.reducer;
