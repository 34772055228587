import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AddEditFormButtonGroup = ({
  action,
  handleClose,
  handleDeleteRow,
  confirmationForEditForm,
  setConfirmationForEditForm,
  selectedRow,
  baseGroups,
}) => {
  const { t } = useTranslation();

  const { handleClearFields } = useFormContext();

  return (
    <Grid item sx={{ width: "100%", mb: 2 }}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <Button type="submit" variant="contained">
            {action === "Add"
              ? t("src.components.Intelligence.add")
              : t("main.another.save")}
          </Button>
          <Button
            color="primary"
            onClick={handleClose}
            sx={{ ml: 1 }}
            variant="text"
          >
            {t("src.components.Intelligence.close")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            onClick={handleClearFields}
            sx={{ ml: 1 }}
            variant="text"
          >
            {action === "Add"
              ? t("src.components.Intelligence.clear")
              : t("src.components.Intelligence.reset")}
          </Button>
          {confirmationForEditForm ? (
            <>
              <Tooltip title="Подтвердить">
                <IconButton
                  color="inherit"
                  onClick={handleDeleteRow}
                  size="small"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Отмена">
                <IconButton
                  color="inherit"
                  onClick={() => setConfirmationForEditForm(null)}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            action === "Update" &&
            handleDeleteRow && (
              <Button
                color="secondary"
                disabled={baseGroups?.includes(selectedRow?.name) || false}
                onClick={() => setConfirmationForEditForm(true)}
                startIcon={<DeleteIcon />}
                sx={{ ml: 1 }}
                variant="outlined"
              >
                {t("src.components.Intelligence.delete", "Удалить элемент")}
              </Button>
            )
          )}
        </Grid>
      </Stack>
    </Grid>
  );
};

export default AddEditFormButtonGroup;
