import React from "react";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";

const CustomMultiTextFieldAutocompleteForObject = ({
  name,
  label,
  options,
  rules,
  multiple,
  size,
  ...rest
}) => {
  const { control, formState } = useFormContext();

  const error = formState.errors[name];
  const req = !!rules?.required;

  return (
    <div style={{ position: "relative", marginBottom: "12px", width: "100%" }}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disabled={!!rest.disabled}
            getOptionLabel={(option) => option.name}
            id={name}
            multiple={multiple}
            onChange={(_, selectedOptions) => {
              onChange(selectedOptions || []);
            }}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!error}
                label={`${label}${req ? " *" : ""}`}
                size={size}
                variant="outlined"
              />
            )}
            size={size}
            value={
              value
                ? options.filter((option) =>
                    value.some((val) => val.name === option.name),
                  )
                : []
            }
          />
        )}
        rules={rules}
      />
      <span
        style={{
          position: "absolute",
          bottom: "4px",
          fontSize: "12px",
          color: "red",
          left: 14,
        }}
      >
        {error && (error?.message || "Unknown error!")}
      </span>
    </div>
  );
};

export default CustomMultiTextFieldAutocompleteForObject;
