import React, { useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AlertMessage from "../../components/Alert/AlertMessage";
import CustomTextField from "../../components/Fields/CustomTextField";
import Footer from "../../components/Footer";
import LoadingIndicator from "../../components/LoadingIndicator";
import { $authHost } from "../../http";

const AzureCreateTenant = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { UserID } = useSelector((state) => state.authReducer);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost.post(`/tenant/create?owner_id=${UserID}`, data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          p: 4,
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={4}
          sx={{ maxWidth: "600px" }}
        >
          <Grid item>
            <Typography variant="h4">
              {t("src.pages.AzureCreateTenant.welcome")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{t("src.pages.AzureCreateTenant.thanks")}</Typography>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {loading && <LoadingIndicator />}
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextField
                  label={t("src.pages.AzureCreateTenant.tenantName")}
                  name="TenantName"
                  required
                />
                <CustomTextField
                  label={t("src.pages.AzureCreateTenant.companyName")}
                  name="TenantCompanyName"
                  required
                />
                <CustomTextField
                  label={t("src.pages.AzureCreateTenant.companyCountry")}
                  name="TenantCompanyCountry"
                  required
                />
                <CustomTextField
                  label={t("src.pages.AzureCreateTenant.companyAddress")}
                  name="TenantCompanyAddress"
                  required
                />
                <CustomTextField
                  label={t("src.pages.AzureCreateTenant.companySite")}
                  name="TenantCompanyWebsite"
                />
                <CustomTextField
                  label={t("src.pages.AzureCreateTenant.companyIIN")}
                  name="TenantCompanyTaxRegistrationCode"
                />
                <AlertMessage />
                <Button
                  sx={{ width: "100%" }}
                  type="submit"
                  variant="contained"
                >
                  {t("src.pages.AzureCreateTenant.send")}
                </Button>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default AzureCreateTenant;
