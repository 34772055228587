import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenants: [],
  selectedTenant: null,
};

const TenantsSlice = createSlice({
  name: "tenants",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setTenants(state, { payload }) {
      state.tenants = payload;
    },
    setSelectedTenant(state, { payload }) {
      const index = state.tenants.findIndex((tenant) => tenant.id === payload);
      state.selectedTenant = state.tenants[index];
    },
  },
});

export const { actions: tenantsActions, reducer: tenantsReducer } =
  TenantsSlice;
export default TenantsSlice.reducer;
