import React from "react";

import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CustomTextField from "../../components/Fields/CustomTextField";

const TenantManagementAdd = ({ handleClose }) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data) => {
    // setLoading(true);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomTextField label="Номер" name="number" required />
          <CustomTextField label="Наименование" name="name" required />
          <Controller
            control={control}
            name="role"
            render={({ field: { ref, ...field }, fieldState: { error } }) => {
              return (
                <Autocomplete
                  {...field}
                  freeSolo
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => field.onChange(value)}
                  onInputChange={(_, data) => {
                    if (data) field.onChange(data);
                  }}
                  options={[1, 2, 3]}
                  renderInput={(params) => (
                    <TextField
                      label="Роль"
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                    />
                  )}
                  size="small"
                  sx={{
                    mb: 3,
                  }}
                />
              );
            }}
            rules={
              {
                // required: 'Обязательное поле',
              }
            }
          />
          <Grid item sx={{ width: "100%", mb: 2 }}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={1}
            >
              <Grid item>
                <Button type="submit" variant="contained">
                  {t("src.components.Intelligence.add")}
                </Button>
                <Button
                  color="primary"
                  onClick={handleClose}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  {t("src.components.Intelligence.close")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => reset()}
                  sx={{ ml: 1 }}
                  variant="text"
                >
                  {t("src.components.Intelligence.clear")}
                </Button>
              </Grid>
            </Stack>
          </Grid>
        </form>
      </FormProvider>
      {/*<BlockFormModal when={isDirty && !isSubmitSuccessful} />*/}
    </>
  );
};

export default TenantManagementAdd;
