import React, { useEffect } from "react";

import { Grid, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useActions } from "../../hook/useActions";
import { $host } from "../../http";
import AlertMessage from "../Alert/AlertMessage";
import CustomTextField from "../Fields/CustomTextField";

export function RegisterForm({ currentLocation }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const methods = useForm({
    mode: "onBlur",
  });
  const { setError, handleSubmit } = methods;

  const { resetAllAlertMessage, setSuccessAlertMessage, setInfoAlertMessage } =
    useActions();

  const onSubmit = async (data) => {
    if (currentLocation) {
      // console.log(data)
      try {
        await $host.post(`/user/invite`, data).then((response) => {
          if (response.status === 201) {
            resetAllAlertMessage();
            setSuccessAlertMessage(
              t(
                "src.sections.auth.RegisterForm.registerSuccess",
                "Регистрация прошла успешно! На ваш адрес электронной почты выслан логин и пароль для входа.",
                "ru",
              ),
            );
          }
        });
      } catch (e) {
        if (
          e.response.status === 401 &&
          e.response.data.detail === "This user already exists"
        ) {
          setError("email", {
            type: "manual",
            message: t(
              "src.sections.auth.RegisterForm.alreadyExist",
              "Пользователь с такой почтой уже зарегитрирован",
            ),
          });
        }
      }
    } else {
      try {
        await $host.post(`/user/reset_password`, data).then((response) => {
          // переделать когда будет reset-password
          if (response.status === 201) {
            setInfoAlertMessage(
              t("src.sections.auth.RegisterForm.resetSuccess"),
            );
          }
        });
      } catch (e) {
        if (
          e.response.status === 404 &&
          e.response.data.detail === "User is not found"
        ) {
          setError("email", {
            type: "manual",
            message: t(
              "src.sections.auth.RegisterForm.notExist",
              "Пользователь с такой почтой не зарегитрирован",
            ),
          });
        }
      }
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userData"))) {
      navigate("/app/dialogs");
    }
  }, [navigate]);

  return (
    <>
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 2 }}
        >
          <CustomTextField
            autoComplete="email"
            autoFocus
            label={t("src.sections.auth.RegisterForm.email")}
            name="email"
            required
            rules={{
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t("main.another.wrongInput"),
              },
            }}
          />
          <Button fullWidth sx={{ mb: 2 }} type="submit" variant="contained">
            {currentLocation
              ? t("src.sections.auth.RegisterForm.register")
              : t("src.sections.auth.RegisterForm.resetPassword", "Сбросить")}
          </Button>
          <Grid container>
            <Grid item>
              <Link href="/basePage/login" variant="body2">
                {t("src.pages.Register.alreadyHaveAccountText")}
                {t("src.pages.Register.loginLinkText")}
              </Link>
            </Grid>
          </Grid>
          <AlertMessage />
        </Box>
      </FormProvider>
    </>
  );
}
