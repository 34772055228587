import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer";

const AzureComplete = () => {
  const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("path");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent="center"
          spacing={4}
          sx={{ maxWidth: "600px" }}
        >
          <Grid item>
            <Typography variant="h4">
              {t("src.pages.AzureRegistration.copyReady")}
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography textAlign="center">
              {t("src.pages.AzureRegistration.thanksForBeing")}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6}>
            <Button
              href={`https://${path}`}
              sx={{ width: "100%" }}
              variant="contained"
            >
              {t("src.pages.AzureRegistration.follow")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default AzureComplete;
