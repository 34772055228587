import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const LandingLayout = () => {
  return (
    <Box sx={{ minHeight: "100%", display: "flex" }}>
      <Outlet />
    </Box>
  );
};

export default LandingLayout;
