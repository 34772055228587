import React from "react";

import { Divider } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import CustomTextField from "../../Fields/CustomTextField";

const AccessSsoSettingsFields = () => {
  const { t } = useTranslation();

  const { control, setValue } = useFormContext();

  return (
    <>
      {t("src.pages.AccessSsoPage.accessSSO")}
      <Controller
        control={control}
        name="is_on"
        render={({ field }) => {
          return (
            <ToggleButtonGroup
              {...field}
              color="primary"
              exclusive
              onChange={(event, value) => {
                setValue(field.name, value);
              }}
              size="small"
              sx={{ pt: 1, mb: 3, ml: 2 }}
            >
              <ToggleButton sx={{ px: 3, py: 1 }} value={true}>
                вкл
              </ToggleButton>
              <ToggleButton sx={{ px: 3, py: 1 }} value={false}>
                выкл
              </ToggleButton>
            </ToggleButtonGroup>
          );
        }}
      />

      <Divider sx={{ mb: 3 }} />

      <CustomTextField
        label={t("src.pages.AccessSsoPage.clientID")}
        name="client_id"
        required
      />
      <CustomTextField
        label={t("src.pages.AccessSsoPage.providerUrl")}
        name="adfs_urls"
        required
      />
      <CustomTextField label="SSO Sign Out URL" name="signout_url" required />
      <CustomTextField
        disabled
        label={t("src.pages.AccessSsoPage.CallbackUrl")}
        name="callback_url"
      />
    </>
  );
};

export default AccessSsoSettingsFields;
