import { Card, Grid, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

import { TitleStack } from "../theme/standarts_styles";

const MainPage = () => {
  const { t } = useTranslation();

  return (
    <Container sx={{ pb: 0 }}>
      <TitleStack>
        <Typography gutterBottom sx={{ mb: 0 }} variant="h4">
          {t("src.pages.MainPage.title")}
        </Typography>
      </TitleStack>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Card sx={{ p: 2 }} variant="outlined">
            {t("src.pages.MainPage.clientInfo")}
          </Card>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Card
              sx={{ p: 2, minWidth: "150px", textAlign: "center" }}
              variant="outlined"
            >
              {t("src.pages.MainPage.balance")}: 0
            </Card>
            <Card
              sx={{ p: 2, minWidth: "150px", textAlign: "center" }}
              variant="outlined"
            >
              {t("src.pages.MainPage.payable")}: 0
            </Card>
          </Stack>
        </Grid>
        <Grid item>
          <Card sx={{ p: 2 }} variant="outlined">
            {t("src.pages.MainPage.contact")}
          </Card>
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <Card
              sx={{ p: 2, minWidth: "150px", textAlign: "center" }}
              variant="outlined"
            >
              BIAnalytics
              <br />0
            </Card>
            <Card
              sx={{ p: 2, minWidth: "150px", textAlign: "center" }}
              variant="outlined"
            >
              AI
              <br />0
            </Card>
            <Card
              sx={{ p: 2, minWidth: "150px", textAlign: "center" }}
              variant="outlined"
            >
              Fort
              <br />0
            </Card>
            <Card
              sx={{ p: 2, minWidth: "150px", textAlign: "center" }}
              variant="outlined"
            >
              UNI-Bi
              <br />0
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainPage;
