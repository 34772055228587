import { useEffect, useState } from "react";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, Card, IconButton, InputAdornment } from "@mui/material";
import Stack from "@mui/material/Stack";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import AlertMessage from "../Alert/AlertMessage";
import CustomTextField from "../Fields/CustomTextField";
import LoadingSpinner from "../modals/loadingSpinner";

const AccountChangePassword = () => {
  const { t } = useTranslation();

  const { resetAllAlertMessage, setSuccessAlertMessage, setErrorAlertMessage } =
    useActions();

  const [showOldPassword, setShowOldPassword] = useState(() => {
    return false;
  });
  const [showNewPassword, setShowNewPassword] = useState(() => {
    return false;
  });
  const [showConfirmPassword, setShowConfirmPassword] = useState(() => {
    return false;
  });
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const [loading, setLoading] = useState(false);
  const registerOptions = {
    pattern: {
      value: /^[!-~]{1,32}$/i,
      message: t("src.pages.AccountPage.ChangePassword.uncorrectedError"),
    },
    required: t("main.another.requiredField"),
  };

  const methods = useForm({
    mode: "onBlur",
  });
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isDirty },
  } = methods;

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await $authHost
        .patch(`user/change_password`, {
          password: data.newPassword,
          old_password: data.oldPassword,
        })
        .then((response) => {
          if (response.status === 201) {
            // reset({})
            resetAllAlertMessage();
            setSuccessAlertMessage(
              t("src.pages.AccountPage.ChangePassword.passwordСhanged"),
            );
          }
        });
    } catch (e) {
      if (
        e.response.status === 401 &&
        e.response.data.detail ===
          "The old and new passwords must not be the same"
      ) {
        setErrorAlertMessage(
          t(
            "src.pages.ChangePassword.differentPassword",
            "Новый пароль должен отличаться от старого!",
          ),
        );
        // setError('newPassword', {
        // 	type: 'manual',
        // 	// message: t('src.pages.ChangePassword.differentPassword', 'Новый пароль должен отличаться от старого!'),
        // })
      }
      if (
        e.response.status === 401 &&
        e.response.data.detail === "The old password is incorrect"
      ) {
        setErrorAlertMessage(
          t("src.pages.AccountPage.ChangePassword.oldPasswordError"),
        );
        // setError('oldPassword', {
        // 	type: 'manual',
        // 	// message: t('src.pages.AccountPage.ChangePassword.oldPasswordError'),
        // })
      }
    } finally {
      setLoading(false);
    }
  };

  const newPassword = useWatch({
    control,
    name: "newPassword",
    defaultValue: "",
  });

  useEffect(() => {
    reset({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [isSubmitSuccessful, reset]);
  return (
    <>
      {loading && <LoadingSpinner />}
      <Box sx={{ flexDirection: "column", px: 0, py: 4 }}>
        <AlertMessage />
        <Card>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{
                  padding: "32px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowOldPassword}
                        >
                          {showOldPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t("src.pages.AccountPage.ChangePassword.oldPassword")}
                  name="oldPassword"
                  required
                  type={showOldPassword ? "text" : "password"}
                />
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowNewPassword}
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t("src.pages.AccountPage.ChangePassword.newPassword")}
                  name="newPassword"
                  required
                  secondErrorText={t(
                    "src.pages.AccountPage.ChangePassword.uncorrectedError",
                  )}
                  type={showNewPassword ? "text" : "password"}
                />
                <CustomTextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          onClick={handleClickShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={t(
                    "src.pages.AccountPage.ChangePassword.confirmPassword",
                  )}
                  name="confirmPassword"
                  required
                  rules={{
                    validate: (value) => {
                      return (
                        value === newPassword ||
                        t("src.pages.AccountPage.ChangePassword.validPassword")
                      );
                    },
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                />
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Button
                    color="primary"
                    disabled={!isDirty}
                    type="submit"
                    variant="contained"
                  >
                    {t("main.another.submitButton", "Сохранить изменения")}
                  </Button>
                  <Button
                    disabled={!isDirty}
                    onClick={() => reset()}
                    variant="text"
                  >
                    {t("main.another.reset", "Сбросить")}
                  </Button>
                </Stack>
              </div>
            </form>
          </FormProvider>
        </Card>
      </Box>
    </>
  );
};

export default AccountChangePassword;
