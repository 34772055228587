import React, { useCallback, useEffect } from "react";

import { FormProvider, useForm } from "react-hook-form";

const BaseAddEditForm = ({ children, selectedRow, onSubmit, fieldsRef }) => {
  const methods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit, reset, getValues } = methods;

  const handleClearFields = useCallback(() => {
    const fieldValues = getValues();
    const fieldsToClear = Object.keys(fieldValues).reduce((acc, fieldName) => {
      // if (Array.isArray(fieldValues[fieldName])) {
      //   acc[fieldName] = []
      // } else {
      acc[fieldName] = "";
      // }
      return acc;
    }, {});
    if (selectedRow) {
      reset(selectedRow); // устанавливаем значения полей из выбранной строки
    } else {
      reset(fieldsToClear); // очистка полей формы согласно имеющимся полям
    }
  }, [getValues, reset, selectedRow]);

  useEffect(() => {
    handleClearFields();
  }, [handleClearFields]);

  return (
    <>
      <FormProvider {...methods} handleClearFields={handleClearFields}>
        <form onSubmit={handleSubmit(onSubmit)} ref={fieldsRef}>
          {children}
        </form>
      </FormProvider>
    </>
  );
};

export default BaseAddEditForm;
