import { useRef, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import SearchFieldInAppBar from "./SearchFieldInAppBar";
import Logo from "../../components/Logo";
import useResponsive from "../../hook/useResponsive";
import { StyledRootAppBar, StyledToolbar } from "../../theme/standarts_styles";

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header() {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const containerRef = useRef(null);
  // const { sseChannelError } = useSelector(state => state.serviceReducer)
  const { status } = useSelector((state) => state.authReducer);

  const currentLocation = status === "Active" || status === "Locked";
  const isDesktop = useResponsive("up", "lg");

  return (
    <StyledRootAppBar>
      <StyledToolbar>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          width="100%"
          // xs={6}
        >
          <Grid item ref={containerRef}>
            {status === "Active" && isDesktop && (
              <Collapse in={checked} orientation="horizontal">
                <SearchFieldInAppBar width="300px" />
              </Collapse>
            )}
          </Grid>
          <Grid item>
            {status === "Active" && isDesktop && (
              <IconButton
                onClick={handleChange}
                sx={{
                  color: "layout.element",
                }}
              >
                <SearchIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <LanguagePopover />
          </Grid>
          <Grid item>{currentLocation && <AccountPopover />}</Grid>
        </Grid>
      </StyledToolbar>
    </StyledRootAppBar>
  );
}
