import React, { useCallback, useEffect, useState } from "react";

import { Card, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";

const SubscriptionTransactions = () => {
  const tableId = "SubscriptionTransactions";
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { setTransactions } = useActions();
  const { transactions } = useSelector((state) => state.subscriptionsReducer);

  const { subscriptionId } = useParams();

  const tableColumns = [
    {
      accessorKey: "Date",
      header: t("src.pages.SubscriptionTransactions.date"),
      accessorFn: (row) => dayjs(row.date).format("DD.MM.YYYY") || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "ResourceName",
      header: t("src.pages.SubscriptionTransactions.transactionName"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "total",
      header: t("src.pages.SubscriptionTransactions.total"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    // {
    //   accessorKey: 'details',
    //   header: 'Детали',
    //   Cell: ({renderedCellValue, row}) => (
    //     <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
    //   ),
    // },
  ];

  const loadData = useCallback(() => {
    setLoading(true);
    try {
      const getData = async () => {
        const response = await $authHost.get(
          `/metriks/all?subscription_id=${subscriptionId}`,
        );
        setTransactions(response.data);
        setLoading(false);
      };
      getData();
    } catch (e) {
      console.log(e);
    }
  }, [setTransactions, subscriptionId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Card sx={{ py: 1, my: 2 }}>
      <MaterialTable
        columns={tableColumns}
        data={transactions}
        id={tableId}
        loading={loading}
        positionExpandColumn="last"
        renderDetailPanel={({ row, table }) => (
          <Typography px={2} variant="body2">
            Тут детали
          </Typography>
        )}
      />
    </Card>
  );
};

export default SubscriptionTransactions;
