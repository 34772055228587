import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CustomDatepicker = ({
  name,
  label,
  required,
  rules,
  inputProps,
  inputLabelProps,
  sx,
  ...props
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  if (required) {
    rules = { required: t("main.another.requiredField"), ...rules };
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              {...field}
              control={control}
              format="DD/MM/YYYY"
              label={`${label}${required || !!rules?.required ? " *" : ""}`}
              onChange={(e) => {
                onChange(e);
              }}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error && (error?.message || "Unknown error!"),
                  FormHelperTextProps: {
                    sx: {
                      position: "absolute",
                      bottom: "-20px",
                    },
                  },
                  InputLabelProps: {
                    shrink: true,
                    ...inputLabelProps,
                  },
                  fullWidth: true,
                  size: "small",
                  variant: "outlined",
                  ...inputProps,
                },
              }}
              sx={{
                mb: 4,
                ...sx,
              }}
              value={value ? dayjs(value) : value}
              {...props}
            />
          </LocalizationProvider>
        );
      }}
      rules={rules}
    />
  );
};

export default CustomDatepicker;
