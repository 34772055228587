import React, { createContext, useCallback, useEffect } from "react";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import TimeoutWarning from "../components/modals/TimeoutWarning";
import { useActions } from "../hook/useActions";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const { logOut, setWarningModalOpen } = useActions();

  const { isWarningModalOpen, access_token, startTime, tokenLifetime } =
    useSelector((state) => state.authReducer);

  const signOut = useCallback(() => {
    logOut();
    navigate("/login");
  }, [logOut, navigate]);

  const sessionLifeTime = tokenLifetime; //время через которое сессия должна быть окончена
  const warningTime = 600000; //за какое время перед окончанием сессии нужно предупредить пользователя

  useEffect(() => {
    // Запуск таймеров проверки жизни сессии
    if (startTime !== null) {
      const now = new Date().getTime();
      const remainingSessionLifeTime = sessionLifeTime - (now - startTime);
      const remainingWarningTime =
        sessionLifeTime - warningTime - (now - startTime);
      const timerSessionLifeTime = setTimeout(() => {
        signOut();
      }, remainingSessionLifeTime);
      const timerWarningTime = setTimeout(() => {
        if (isWarningModalOpen === false) {
          setWarningModalOpen(true);
        }
      }, remainingWarningTime);
      return () => {
        clearTimeout(timerSessionLifeTime);
        clearTimeout(timerWarningTime);
      };
    }
  }, [
    access_token,
    isWarningModalOpen,
    sessionLifeTime,
    setWarningModalOpen,
    signOut,
    startTime,
  ]);

  const value = {
    signOut,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}

      {isWarningModalOpen && (
        <TimeoutWarning
          isOpen={isWarningModalOpen}
          onRequestClose={() => {
            setWarningModalOpen(false);
          }}
        />
      )}
    </AuthContext.Provider>
  );
};
