import React, {useState} from "react";

import "./event_styles.css";
import Logo from "./assets/aspex.png";
import speaker1 from "./assets/speaker1.png";
import speaker2 from "./assets/speaker2.png";
import speaker3 from "./assets/speaker3.png";
import speaker4 from "./assets/speaker4.png";
import speaker5 from "./assets/speaker5.png";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import LoadingIndicator from "../../../components/LoadingIndicator";
import CustomTextField from "../../../components/Fields/CustomTextField";
import {Button, Typography} from "@mui/material";
import {$host} from "../../../http";

const Landing16Event = () => {
  document.title = 'Встреча для лидеров бизнеса 16.04';

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const methods = useForm({
    mode: "onBlur",
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    setError(null);
    setLoading(true);
    try {
      await axios.post(`https://my-bk-dev.aspex.cloud/mailchimp/marketing/subscribe/business-leaders`, data);
      setSuccess(true);
    } catch (e) {
      setError(
        "Произошла ошибка. Пожалуйста, проверьте введенные данные и повторите попытку.",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="body">
      <header>
        <img
          src={Logo}
          style={{
            width: "10vw",
            maxWidth: "200px",
            minWidth: "70px"
          }}
        />
        <p>Закрытая встреча для лидеров бизнеса: инсайты, стратегии, технологии будущего!🚀</p>
      </header>
      {/*<nav>*/}
      {/*  <a href="#">О мероприятии</a>*/}
      {/*  <a href="#">Расписание/Спикеры</a>*/}
      {/*  <a href="#">Зарегистрироваться</a>*/}
      {/*  <a href="#">Контакты</a>*/}
      {/*</nav>*/}
      <div className="container">
        <div className="content">
          <div className="main-content">
            <h2 style={{ marginTop: "0px" }}>
              Цифровая трансформация 2025: Big Data и AI — технологии, которые меняют бизнес уже сегодня
            </h2>
            <p>
              Закрытое мероприятие для CEO, CDO и CIO. Реальные кейсы, цифры и инсайты, которые помогут вывести ваш бизнес на новый уровень
            </p>
            <p>
              <strong>Дата и время: </strong>
              16 апреля 2025, 18:30–23:30 (ALА)
            </p>
            <p>
              <strong>Место проведения: </strong>
              Rixos Almaty, проспект Сейфуллина, 506, Алматы
            </p>
            <a className="register-btn" href="#reg">
              Зарегистрироваться
            </a>
          </div>
          <div className="sidebar">
            <h3>Что вас ждет?</h3>
            <ul>
              <li>Выступления ведущих экспертов индустрии с актуальными кейсами</li>
              <li>Панельные дискуссии о будущем технологий и цифровизации</li>
              <li>Практические примеры внедрения ИИ и Big Data</li>
              <li>Эксклюзивный нетворкинг с лидерами рынка в неформальной обстановке</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="schedule">
        <h2>Расписание</h2>
        <div className="schedule-list">
          <div className="topic">
            <div className="info">
              <p>18:30 - 19:00</p>
              <p>
                <strong>Кофе-брейк и нетворкинг</strong>
              </p>
            </div>
          </div>

          <div className="topic">
            <div className="info">
              <p>19:00-19:10</p>
              <p>
                <strong>Вступительная речь</strong>
              </p>
            </div>
          </div>

          <div className="topic">
            <div className="info">
              <p>19:10-19:40</p>
              <p>
                Тема 1:
                <strong>
                  {" "}
                  Big Data без головной боли: Arenadata как универсальный
                  инструмент для бизнеса и технологий{" "}
                </strong>
              </p>
            </div>
            <div style={{ textAlign: "start" }}>
              <p className="anonce">Спикер:</p>
              <div className="speaker2">
                <img alt="Дмитрий Шеранов" src={speaker1} />
                <div>
                  <h4>Дмитрий Шеранов</h4>
                  <p>Arenadata, International Business Development Director </p>
                </div>
              </div>
            </div>
          </div>

          <div className="topic">
            <div className="info">
              <p>19:40-20:10</p>
              <p>
                Тема 2:
                <strong>
                  {" "}
                  ИИ-решения с мгновенной выгодой: реальные кейсы с окупаемостью от 2 дней
                </strong>
              </p>
            </div>
            <div style={{ textAlign: "start" }}>
              <p className="anonce">Спикер:</p>
              <div className="speaker2">
                <img alt="Speaker 1" src={speaker2} />
                <div>
                  <h4>Вадим Тигин</h4>
                  <p>CTO Aspex </p>
                </div>
              </div>
            </div>
          </div>

          <div className="topic">
            <div className="info">
              <p>20:10-20:30</p>
              <p>
                Тема 3:
                <strong>
                  {" "}
                  Результаты исследования болей и проблемных зон при
                  цифровизации бизнеса.
                </strong>
              </p>
              <p style={{ fontStyle: "italic" }}>
                Эксклюзивные данные опроса CIO и CDO крупнейших компаний Казахстана
              </p>
            </div>
            <div style={{ textAlign: "start" }}>
              <p className="anonce">Спикеры:</p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "left",
                  gap: "15px",
                }}
              >
                <div className="speaker2">
                  <img alt="Speaker 1" src={speaker3} />
                  <div>
                    <h4>Арман Шокпаров</h4>
                    <p>Founding Partner, People Consulting</p>
                  </div>
                </div>
                <div className="speaker2">
                  <img alt="Speaker 1" src={speaker4} />
                  <div>
                    <h4>Ардак Маметжанова</h4>
                    <p>Partner, People Consulting</p>
                  </div>
                </div>
                <div className="speaker2">
                  <img alt="Speaker 1" src={speaker5} />
                  <div>
                    <h4>Александр Прокопенко</h4>
                    <p>Partner, People Consulting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="feedback" id="reg">
        <div className="header_form">
          <h3>
            Количество мест ограничено!
          </h3>
          <h2>
            Заполните форму, чтобы подтвердить свое участие и попасть в список приглашённых.
          </h2>
        </div>
        <div className="container2">
          <div className="content2">
            <div className="main-content2">
              <FormProvider {...methods}>
                {loading && <LoadingIndicator />}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <CustomTextField
                    label="Ваше имя"
                    name="first_name"
                    required
                  />
                  <CustomTextField
                    label="Электронная почта"
                    name="email"
                    required
                    rules={{
                      required: t("main.another.requiredField"),
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: t("main.another.wrongInput"),
                      },
                    }}
                  />
                  <CustomTextField
                    label="Компания"
                    name="company_name"
                    required
                  />
                  <CustomTextField
                    label="Должность"
                    name="occupation"
                    required
                  />
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    Зарегистрироваться
                  </Button>
                  {error && (
                    <Typography color="error" pt={2} variant="body2">
                      {error}
                    </Typography>
                  )}
                  {success && (
                    <Typography color="success" pt={2} variant="body2">
                      Ваши данные отправлены. Большое спасибо за регистрацию!
                    </Typography>
                  )}
                </form>
              </FormProvider>
            </div>
            <div className="sidebar2">
              <h3>Почему стоит прийти?</h3>
              <ul>
                <li>Готовые решения и успешные кейсы, которые можно внедрить уже завтра</li>
                <li>Актуальные инсайты от ведущих экспертов рынка</li>
                <li>Эксклюзивные результаты исследования цифровизации в бизнесе</li>
                <li>Нетворкинг с топ-менеджерами крупнейших компаний</li>
                <li>Закрытая встреча, куда приглашаются только лидеры отрасли</li>
              </ul>
            </div>
          </div>
        </div>

      </section>
      <section className="contacts">
        <h2>Контакты</h2>
        <p>Готовы внедрять ИИ и Big Data?</p>
        <p>Напишите нам – обсудим решения для вашего бизнеса</p>
        <p>info@aspex.kz</p>
      </section>
      <footer>&copy; ASPEX Group 2016-2025. All Rights Reserved.</footer>
    </div>
  );
};

export default Landing16Event;
