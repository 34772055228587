import { useCallback, useEffect, useState } from "react";

import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, Card, Container, Tooltip, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Iconify from "../../components/iconify";
import MaterialTable from "../../components/MaterialTable";
import { TableCellsWrap } from "../../components/TableCellsWrap";
import { useActions } from "../../hook/useActions";
import { $authHost } from "../../http";
import { ButtonStack, TitleStack } from "../../theme/standarts_styles";

const MySubscriptions = () => {
  const tableId = "MySubscriptions";

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { subscriptions } = useSelector((state) => state.subscriptionsReducer);

  const { setSubscriptions, setSelectedSubscription } = useActions();

  const tableColumns = [
    {
      accessorKey: "SubscriptionID",
      header: t("src.pages.MySubscriptions.subscriptionName"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "Products",
      header: t("src.pages.MySubscriptions.productName"),
      accessorFn: (row) => row.Products?.ProductName || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "SubscriptionStatus",
      header: t("src.pages.MySubscriptions.state"),
      accessorFn: (row) => row.SubscriptionStatus?.Status || "",
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "Balance",
      header: t("src.pages.MySubscriptions.balance"),
      Cell: ({ renderedCellValue, row }) => (
        <TableCellsWrap>{renderedCellValue}</TableCellsWrap>
      ),
    },
    {
      accessorKey: "actions",
      size: 100,
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ renderedCellValue, row }) => {
        return (
          <>
            <Tooltip title={t("main.another.pay")}>
              <IconButton
                color="inherit"
                onClick={() => handlePay(row.original.id)}
                size="large"
              >
                <PaymentIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("main.another.management")}>
              <IconButton
                color="inherit"
                onClick={() => handleManage(row)}
                size="large"
              >
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleManage = (row) => {
    const id = row.original?.SubscriptionID;
    setSelectedSubscription(id);
    navigate(`/my-subscriptions/${id}`);
  };

  const handlePay = (id) => {
    // setSelectedSubscription(id);
    // navigate(`/users/${id}/edit`);
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await $authHost.get("/subscription/get_all");
      setSubscriptions(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [setSubscriptions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Container>
      <TitleStack>
        <Typography sx={{ mr: 4 }} variant="h4">
          {t("src.pages.MySubscriptions.mySubscriptions")}
        </Typography>
      </TitleStack>
      <ButtonStack>
        <Button
          aria-haspopup="true"
          href={`${window.location.origin}/azure/create-subscription`}
          rel="noreferrer"
          startIcon={<Iconify icon="eva:plus-fill" />}
          sx={{ mr: 3 }}
          target="_blank"
          variant="contained"
        >
          {t("src.pages.MySubscriptions.addSubscription")}
        </Button>
      </ButtonStack>
      <Card sx={{ pt: 1 }}>
        <MaterialTable
          columns={tableColumns}
          data={subscriptions}
          id={tableId}
          loading={loading}
        />
      </Card>
    </Container>
  );
};

export default MySubscriptions;
