import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CustomAutocomplete = ({
  name,
  label,
  options,
  required,
  rules,
  InputLabelProps,
  inputProps,
  sx,
  value: customValue,
  getOptionLabel = (option) => option,
  isOptionEqualToValue = (option, value) => option === value,
  ...props
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  if (required) {
    rules = { required: t("main.another.requiredField"), ...rules };
  }

  // TODO: Добавить переводы
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => {
        return (
          <Autocomplete
            {...field}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            noOptionsText="Нет доступных вариантов"
            onChange={(e, value) => onChange(value)}
            options={options || []}
            sx={{
              mb: 4,
              ...sx,
            }}
            value={customValue ? customValue : value}
            {...props}
            renderInput={(params) => (
              <TextField
                {...params}
                FormHelperTextProps={{
                  sx: {
                    position: "absolute",
                    bottom: "-20px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  ...InputLabelProps,
                }}
                error={!!error}
                fullWidth
                helperText={error && (error?.message || "Unknown error!")}
                label={`${label}${required || !!rules?.required ? " *" : ""}`}
                size="small"
                variant="outlined"
                {...inputProps}
              />
            )}
          />
        );
      }}
      rules={rules}
    />
  );
};
export default CustomAutocomplete;
