import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accounts: [],
  account: null,
  selectedAccount: null,
};

const AccountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    logOut() {
      return initialState;
    },
    setMyAccounts(state, { payload }) {
      state.accounts = payload;
    },
    setMyAccount(state, { payload }) {
      state.account = payload;
    },
    setSelectedMyAccount(state, { payload }) {
      const index = state.accounts.findIndex(
        (account) => account.InvoiceID === payload,
      );
      state.selectedAccount = state.accounts[index];
    },
  },
});

export const { actions: accountsActions, reducer: accountsReducer } =
  AccountsSlice;
export default AccountsSlice.reducer;
